import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import FormHeader from "../../../components/form-header/form-header"
import { type } from "@testing-library/user-event/dist/type"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { LocationPayload } from "../../../data/services/Location-api-service/location-payload"
import LocationApiService from "../../../data/services/Location-api-service/location-api-service"
import axios, { AxiosError } from "axios"
import { useDispatch } from "react-redux"
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action"

const EditLocation = () => {
    const locationApiService: LocationApiService = new LocationApiService();
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [attribute, setAttribute] = useState([])
    const { state } = useLocation();
    const id = state.id
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('EDIT LOCATION'));

    const getLocationById = async () => {
        try {
            let data = await locationApiService.getLocationById(id);
            locationData(data);

            console.log("getAttributeById", data)
        } catch (error) {
            console.log("getAttributeById Error");
            console.log(error);
        }
    }

    const validateLocation = async (event: any) => {
        event.preventDefault();

        if (!name) {
            toast.error("Please Enter Name", { containerId: 'TR' });
            return;
        }
        if (!code) {
            toast.error("Please Enter Code", { containerId: 'TR' });
            return;
        }

        await updateLocation();
    }

    const updateLocation = async () => {
        try {
            let payload: LocationPayload = {
                name: name,
                code: code,
            }
            await locationApiService.editLocation(id, payload);
            toast.success('Location Updated Successfully', { containerId: 'TR' });
            navigate('/main-layout/location-datatable')
        } catch (error: any) {
            console.log("Attribute Error");
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const locationData = (data: any) => {
        setName(data.name);
        setCode(data.code);
    }

    useEffect(() => {
        getLocationById();
    }, []);


    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const goBack = () => {
        navigate('/main-layout/location-datatable');
    }

    return <>
        {/* <!-- Container-fluid starts--> */}
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={validateLocation}>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Name</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control

                                                    type="text" value={name}
                                                    autoComplete="off"
                                                    onChange={e => { setName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Code</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" value={code}
                                                    autoComplete="off"
                                                    onChange={e => { setCode(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row >
                                            <Col xs={8} className="d-flex justify-content-end">
                                                <Button type="button" variant="danger" className="secondary mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" >Update</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>
                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>

}
export default EditLocation