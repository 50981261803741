import HttpClientWrapper from "../../api/http-client-wrapper";

class CategoriesApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllCategories = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/categories/fetchAll');
            console.log("CategoryApiService getAllCategories() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAllCategoriesTreeView =async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/categories/treeView');
            console.log("CategoryApiService getAllCategoriesTreeView() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getCategoryById = async (id:any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/categories/'+ id );
            console.log("CategoryApiService getCategoryById() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    deleteCategory = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.delete('/v1/categories/'+id );
            console.log("ProductApiService deleteCategory() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }
    createCategories = async (payload: any) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/categories', payload);
            console.log("CategoryApiService createCategories() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }
    updateCategory = async(id:any, payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.put('/v1/categories/'+id, payload );
            console.log("ProductApiService updateCategory() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }
    createSubCategories = async(id:any,payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.post('/v1/categories/'+id+'/subCategories',payload );
            console.log("CategoryApiService createVariantTypeDependent() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }


}
export default CategoriesApiService