import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormHeader from "../../../components/form-header/form-header";
import ProductApiService from "../../../data/services/product/product-api-service";
import ProductTag from "./product-tag/product-tag";

const ProductSpecificiation = (props: any) => {
    const productApiService: ProductApiService = new ProductApiService;
    const [productDimension, setProductDimension] = useState('')
    const [piecesPerSleeve, setPiecesPerSleeve] = useState('')
    const [pieceOrCarton, setPieceOrCarton] = useState('')
    const [cartonLength, setCartonLength] = useState('')
    const [cartonWidth, setCartonWidth] = useState('')
    const [cartonHeight, setCartonHeight] = useState('')
    const [cartonCBM, setCartonCBM] = useState('')
    const [cartonWeight, setCartonWeight] = useState('')
    const [manufactured, setManufactured] = useState('');
    const [qualityProductCertified, setQualityProductCertified] = useState('')
    const [factoryFoodSafetyCertified, setFactoryFoodSafetyCertified] = useState('')
    const [industriallyCompostable, setIndustriallyCompostable] = useState('')
    const [categories, setCategories] = useState<any>([]);
    const [productTemplateDataList, setProductTemplateDataList] = useState<any[]>([]);
    const [mouldFee, setMouldFee] = useState('');
    const [moq, setMoq] = useState("");

    let navigate: any = useNavigate();

    const submitProductSpecification = () => {
        try {
            validateAndSaveSpecificationsFormData();
            props.onNextCallback();
        } catch (error:any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const validateAndSaveSpecificationsFormData =  () => {
        try {
            const productFormData = props?.productFormData;
            if (productFormData.has('specifications')) {
                productFormData.delete('specifications');
            }
            productFormData.append('specifications', JSON.stringify(productTemplateDataList));

            console.log(JSON.stringify(productTemplateDataList), 'stringyfy');

            if (productFormData.has('pieceOrCarton')) {
                productFormData.delete('pieceOrCarton');
            }
            productFormData.append('pieceOrCarton', pieceOrCarton);

            if (productFormData.has('mouldFees')) {
                productFormData.delete('mouldFees');
            }
            productFormData.append('mouldFees', 'NO');

            if (productFormData.has('piecesPerSleeve')) {
                productFormData.delete('piecesPerSleeve');
            }
            productFormData.append('piecesPerSleeve', piecesPerSleeve);

            if (productFormData.has('cartonLength')) {
                productFormData.delete('cartonLength');
            }
            productFormData.append('cartonLength', cartonLength);

            if (productFormData.has('cartonWidth')) {
                productFormData.delete('cartonWidth');
            }
            productFormData.append('cartonWidth', cartonWidth);

            if (productFormData.has('cartonHeight')) {
                productFormData.delete('cartonHeight');
            }
            productFormData.append('cartonHeight', cartonHeight);

            if (productFormData.has('cartonWeight')) {
                productFormData.delete('cartonWeight');
            }
            productFormData.append('cartonWeight', cartonWeight);

            if (productFormData.has('cartonCBM')) {
                productFormData.delete('cartonCBM');
            }
            productFormData.append('cartonCBM', cartonCBM);

            if (productFormData.has('manufactured')) {
                productFormData.delete('manufactured');
            }
            productFormData.append('manufactured', manufactured);

            if (productFormData.has('qualityProductCertified')) {
                productFormData.delete('qualityProductCertified');
            }
            productFormData.append('qualityProductCertified', qualityProductCertified);

            if (productFormData.has('factoryFoodSafetyCertified')) {
                productFormData.delete('factoryFoodSafetyCertified');
            }
            productFormData.append('factoryFoodSafetyCertified', factoryFoodSafetyCertified);

            if (productFormData.has('industriallyCompostable')) {
                productFormData.delete('industriallyCompostable');
            }
            productFormData.append('industriallyCompostable', industriallyCompostable);

            if (productFormData.has('productDimension')) {
                productFormData.delete('productDimension');
            }
            productFormData.append('productDimension', productDimension);

            if (productFormData.has('mouldFees')) {
                productFormData.delete('mouldFees');
            }
            productFormData.append('mouldFees', mouldFee);

            // await productApiService.createProduct(productFormData);
            // toast.success('Product Saved Successfully', { containerId: 'TR' });
            // navigate('/main-layout/product-container');
        } catch (error: any) {
            console.log(error);
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    }


    const getProductTemplate = async () => {
        try {
            let data = await productApiService.getAllProductTemplate();
            console.log('getAllProductTemplate data', data);
            addValueObj(data.attributes);
            console.log(productTemplateDataList, 'productList');
        } catch (error) {
            console.log(error);
        }
    }

    const addValueObj = async (data: any[]) => {
        // try {
        for (let i = 0; i < data.length; i++) {
            data[i].value = '';
        }
        console.log(data, 'productTemp')
        setProductTemplateDataList(data);
        // } catch (error) {
        //     console.log(error);
        // }
    }
    const handleCartonLengthChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setCartonLength(inputValue);
        }
    };

    const handlePiecesPerSleeveChange = (e: any) => {
        const piecesPerSleeveValue = e.target.value;
        if (/^[1-9]\d*\.?\d{0,2}$/.test(piecesPerSleeveValue) || piecesPerSleeveValue === '') {
            setPiecesPerSleeve(piecesPerSleeveValue);
        }
    }

    const handlePieceOrCartonChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*$/.test(inputValue) || inputValue === '') {
            setPieceOrCarton(inputValue);
        }
    };

    const handleCartonWidthChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setCartonWidth(inputValue);
        }
    };
    const handleCartonHeightChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setCartonHeight(inputValue);
        }
    };

    const handleCartonWeightChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setCartonWeight(inputValue);
        }
    };

    const handleCartonCBMChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setCartonCBM(inputValue);
        }
    };

    // const handleManufacturedchange = (e: any) => {
    //     const inputValue = e.target.value;
    //     if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
    //         setManufactured(inputValue);
    //     }
    // };



    const handleIndustriallyCompostableChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setIndustriallyCompostable(inputValue);
        }
    };

    const handleFactoryFoodSafetyCertifiedChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setFactoryFoodSafetyCertified(inputValue);
        }
    };

    const handleProductDimensionChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setProductDimension(inputValue);
        }
    };


    const handleInputChange = (index: number, event: any) => {
        const updatedList = productTemplateDataList.map((item, idx) => {
            if (index === idx) {
                return { ...item, value: event.target.value };
            }
            return item;
        });
        setProductTemplateDataList(updatedList);
    };

    const goBack = () => {
        props.onPreviousCallback();
    }

    useEffect(() => {
        getProductTemplate();

    }, [props]);

    return <>
        <Container>
            <Row className="mt-5">
                <Col className="">
                    <Container fluid>
                        <Row>
                            <Form>
                                {/* <Form.Group className="mb-3" controlId="piecesPerSleeveCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Pieces Per Sleeve</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={piecesPerSleeve}
                                                onChange={handlePiecesPerSleeveChange}
                                                placeholder="Enter Pieces Per Sleeve" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group> */}
                                <Form.Group className="mb-3" controlId="pieceOrCartonCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Pieces Or Carton</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={pieceOrCarton}
                                                autoComplete="off"
                                                onChange={handlePieceOrCartonChange}
                                                placeholder="Enter Pieces or Carton" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="cartonLengthCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton Length</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={cartonLength}
                                                autoComplete="off"
                                                onChange={handleCartonLengthChange}
                                                placeholder="Enter Carton Length" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="cartonWidthCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton Width</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={cartonWidth}
                                                autoComplete="off"
                                                onChange={handleCartonWidthChange}
                                                placeholder="Enter Carton Width" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="cartonHeightCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton Height</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={cartonHeight}
                                                autoComplete="off"
                                                onChange={handleCartonHeightChange}
                                                placeholder="Enter Carton Height" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="cartonWeightCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton Weight</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={cartonWeight}
                                                autoComplete="off"
                                                onChange={handleCartonWeightChange}
                                                placeholder="Enter Carton Weight" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="cartonCBMCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton CBM</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={cartonCBM}
                                                autoComplete="off"
                                                onChange={handleCartonCBMChange}
                                                placeholder="Enter Carton CBM" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="MoqCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Moq</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={moq}
                                                onChange={e => { setMoq(e.target.value) }}
                                                placeholder="Enter MOQ" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group> */}
                                <Form.Group className="mb-3" controlId="ModuleFeeCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Mould Fees</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={mouldFee}
                                                autoComplete="off"
                                                onChange={e => { setMouldFee(e.target.value) }}
                                                placeholder="Enter Mould Fees" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="manufactureId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Manufactured</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={manufactured}
                                                autoComplete="off"
                                                onChange={e => { setManufactured(e.target.value) }}
                                                placeholder="Enter Manufacture" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="qualityProductCertifiedId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Quality Product Certified</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={qualityProductCertified}
                                                autoComplete="off"
                                                onChange={e => { setQualityProductCertified(e.target.value) }}
                                                placeholder="Enter Quality Product Certified" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="industriallyCompostableId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Industrially Compostable</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={industriallyCompostable}
                                                autoComplete="off"
                                                onChange={e => { setIndustriallyCompostable(e.target.value) }}
                                                placeholder="Enter Industrially Compostable" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="factoryFoodSafetyCertifiedId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Factory Food Safety Certified</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={factoryFoodSafetyCertified}
                                                autoComplete="off"
                                                onChange={e => { setFactoryFoodSafetyCertified(e.target.value) }}
                                                placeholder="Enter Food Factory Safety Certified" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="productDimensionId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Product Diemnsion</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={productDimension}
                                                autoComplete="off"
                                                onChange={e => { setProductDimension(e.target.value) }}
                                                placeholder="Enter Product Dimension" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                {productTemplateDataList && productTemplateDataList.map((attribute: any, idx: any) => (
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={3}>
                                                <Form.Label>{attribute.name}</Form.Label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control type="text"
                                                    value={attribute.value}
                                                    autoComplete="off"
                                                    onChange={e => { handleInputChange(idx, e) }}
                                                    placeholder={'Enter ' + attribute.name} />
                                                <Form.Text className="text-muted"></Form.Text>
                                            </Col>
                                        </Row>
                                    </Form.Group>))}
                                <Form.Group>
                                    <Row className="justify-content-end">
                                        <Col>
                                            <Col className="pull-rght text-end">
                                            <Button type="button" variant="danger" className="my-4 mx-2" onClick={goBack} >
                                                Back
                                            </Button>

                                                <Button type="button" className="btn btn-primary my-4 mx-2"
                                                    onClick={submitProductSpecification}
                                                >Next</Button>
                                                {/* <Button type="button" className="btn btn-primary my-4 mx-2"
                                                    onClick={validateAndSaveSpecificationsFormData}
                                                >Save</Button> */}

                                            </Col>
                                        </Col>
                                    </Row>
                                </Form.Group>

                            </Form>
                        </Row>
                    </Container>
                </Col>

            </Row>

        </Container>
    </>
}
export default ProductSpecificiation;