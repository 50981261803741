import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProductApiService from "../../../../data/services/product/product-api-service";
import DeleteProductGallery from "../delete-product-gallery";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditProductSpecification = (props: any) => {
    const productApiService: ProductApiService = new ProductApiService();
    const [productDimension, setProductDimension] = useState(props?.data.materialCode);
    const [piecesPerSleeve, setPiecesPerSleeve] = useState(props?.data.piecePerSleeve);
    const [pieceOrCarton, setPieceOrCarton] = useState('');
    const [cartonLength, setCartonLength] = useState(1.1);
    const [cartonWidth, setCartonWidth] = useState(1.1);
    const [cartonHeight, setCartonHeight] = useState(1.1);
    const [cartonCBM, setCartonCBM] = useState('');
    const [cartonWeight, setCartonWeight] = useState(props?.data.cartonWeight);
    const [manufactured, setManufactured] = useState('')
    const [qualityProductCertified, setQualityProductCertified] = useState('')
    const [factoryFoodSafetyCertified, setFactoryFoodSafetyCertified] = useState('')
    const [industriallyCompostable, setIndustriallyCompostable] = useState('')
    const [productTemplateDataList, setProductTemplateDataList] = useState<any[]>([]);
    const [mouldFee, setMouldFee] = useState('');
    let navigate: any = useNavigate();
    

    const submitProductSpecification = () => {
        try {
            validateAndSaveSpecificationsFormData();
            props.onNextCallback();
        } catch (error:any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }


    const validateAndSaveSpecificationsFormData = async () => {
        try {
            const productFormData = props.productFormData;
            if (productFormData.has('specifications')) {
                productFormData.delete('specifications');
            }
            productFormData.append('specifications', JSON.stringify(productTemplateDataList));

            console.log(JSON.stringify(productTemplateDataList), 'stringyfy');

            if (productFormData.has('pieceOrCarton')) {
                productFormData.delete('pieceOrCarton');
            }
            productFormData.append('pieceOrCarton', pieceOrCarton);


            if (productFormData.has('mouldFees')) {
                productFormData.delete('mouldFees');
            }
            productFormData.append('mouldFees', 'NO');

            if (productFormData.has('piecesPerSleeve')) {
                productFormData.delete('piecesPerSleeve');
            }
            productFormData.append('piecesPerSleeve', '20');

            if (productFormData.has('cartonLength')) {
                productFormData.delete('cartonLength');
            }
            productFormData.append('cartonLength', cartonLength);

            if (productFormData.has('cartonWidth')) {
                productFormData.delete('cartonWidth');
            }
            productFormData.append('cartonWidth', cartonWidth);

            if (productFormData.has('cartonHeight')) {
                productFormData.delete('cartonHeight');
            }
            productFormData.append('cartonHeight', cartonHeight);

            if (productFormData.has('cartonWeight')) {
                productFormData.delete('cartonWeight');
            }
            productFormData.append('cartonWeight', cartonWeight);

            if (productFormData.has('cartonCBM')) {
                productFormData.delete('cartonCBM');
            }
            productFormData.append('cartonCBM', cartonCBM);

            if (productFormData.has('manufactured')) {
                productFormData.delete('manufactured');
            }
            productFormData.append('manufactured', manufactured);

            if (productFormData.has('qualityProductCertified')) {
                productFormData.delete('qualityProductCertified');
            }
            productFormData.append('qualityProductCertified', qualityProductCertified);

            if (productFormData.has('factoryFoodSafetyCertified')) {
                productFormData.delete('factoryFoodSafetyCertified');
            }
            productFormData.append('factoryFoodSafetyCertified', factoryFoodSafetyCertified);

            if (productFormData.has('industriallyCompostable')) {
                productFormData.delete('industriallyCompostable');
            }
            productFormData.append('industriallyCompostable', industriallyCompostable);

            if (productFormData.has('productDimension')) {
                productFormData.delete('productDimension');
            }
            productFormData.append('productDimension', productDimension);

            if (productFormData.has('mouldFees')) {
                productFormData.delete('mouldFees');
            }
            productFormData.append('mouldFees', mouldFee);

            for (const pair of productFormData.entries()) {
                console.log(pair[0], pair[1]);
            }
            // await productApiService.editProduct(props?.id, productFormData);
            // toast.success('Product Updated Successfully', { containerId: 'TR' });
            // navigate('/main-layout/product-container');
        } catch (error: any) {
            throw error;
        }
    }


    const handleProductDimensionChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
            setProductDimension(inputValue);
        }
    };


    const handleInputChange = (index: number, event: any) => {
        const updatedList = productTemplateDataList.map((item, idx) => {
            if (index === idx) {
                return { ...item, value: event.target.value };
            }
            return item;
        });
        setProductTemplateDataList(updatedList);
    };

    const goBack = () => {
        try {
            props.onPreviousCallback();
        } catch (error: any) {
            throw (error);
        }
    }

    const updateEditSpecification = (data: any) => {
        try {
            setPiecesPerSleeve(data.piecePerSleeve);
            setPieceOrCarton(data.pieceOrCarton);
            setCartonLength(data.cartonLength);
            setCartonWidth(data.cartonWidth);
            setCartonHeight(data.cartonHeight);
            setCartonCBM(data.cartonCbm);
            setCartonWeight(data.cartonWeight);
            setProductDimension(data.productDimension);
            setMouldFee(data.mouldFees);
            setManufactured(data.manufactured);
            setQualityProductCertified(data.qualityProductCertified);
            setFactoryFoodSafetyCertified(data.factoryFoodSafetyCertified);
            setIndustriallyCompostable(data.industriallyCompostable);
            setProductTemplateDataList(data.specifications);
        } catch (error: any) {
            throw (error);
        }

    }

    const handleCartonLengthChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setCartonLength(inputValue);
        }
    };

    const handlePieceOrCartonChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setPieceOrCarton(inputValue);
        }
    };

    const handleCartonWidthChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setCartonWidth(inputValue);
        }
    };
    const handleCartonHeightChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setCartonHeight(inputValue);
        }
    };

    const isNumeric = (inputValue:string) => {
        return (inputValue == '' || /^[1-9]\d*\.?\d{0,2}$/.test(inputValue));
    }

    const handleCartonWeightChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setCartonWeight(inputValue);
        }
    };

    const handleCartonCBMChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setCartonCBM(inputValue);
        }
    };


    const getProductById = async () => {
        try {
            let data = await productApiService.getProductById(props.productId);
            updateEditSpecification(data);
            console.log("getProductById", data)
        } catch (error) {
            console.log("getProductById Error");
            console.log(error);
        }
    }

    useEffect(() => {
        getProductById();
    }, [props.data, props.productId]);

    return <>
        <Container>
            <Row className="mt-5">
                <Col className="">
                    <Container fluid>
                        <Row>
                            <Form>
                            <Form.Group className="mb-3" controlId="cartonCBMCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton CBM</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={cartonCBM}
                                                onChange={handleCartonCBMChange}
                                                placeholder="Enter Carton CBM" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="cartonLengthCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton Length</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={cartonLength}
                                                onChange={handleCartonLengthChange}
                                                placeholder="Enter Carton Length" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="cartonWidthCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton Width</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={cartonWidth}
                                                onChange={handleCartonWidthChange}
                                                placeholder="Enter Carton Width" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="cartonHeightCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton Height</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={cartonHeight}
                                                onChange={handleCartonHeightChange}
                                                placeholder="Enter Carton Height" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="cartonWeightCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Carton Weight</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={cartonWeight}
                                                onChange={handleCartonWeightChange}
                                                placeholder="Enter Carton Weight" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="pieceOrCartonCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Pieces per Carton</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={pieceOrCarton}
                                                onChange={handlePieceOrCartonChange}
                                                placeholder="Enter Pieces per Carton" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                              <Form.Group className="mb-3" controlId="ModuleFeeCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Mould Fees</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={mouldFee}
                                                onChange={e => { setMouldFee(e.target.value) }}
                                                placeholder="Enter Mould Fees" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="manufactureId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Manufactured</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={manufactured}
                                                onChange={e => { setManufactured(e.target.value) }}
                                                placeholder="Enter Manufacture" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                
                                <Form.Group className="mb-3" controlId="qualityProductCertifiedId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Quality Product Certified</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={qualityProductCertified}
                                                onChange={e => { setQualityProductCertified(e.target.value) }}
                                                placeholder="Enter Quality Product Certified" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="industriallyCompostableId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Industrially Compostable</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={industriallyCompostable}
                                                onChange={e => { setIndustriallyCompostable(e.target.value) }}
                                                placeholder="Enter Industrially Compostable" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="factoryFoodSafetyCertifiedId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Factory Food Safety Certified</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={factoryFoodSafetyCertified}
                                                onChange={e => { setFactoryFoodSafetyCertified(e.target.value) }}
                                                placeholder="Enter Food Factory Safety Certified" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="productDimensionId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Product Diemnsion</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                disabled={props.write}
                                                value={productDimension}
                                                onChange={handleProductDimensionChange}
                                                placeholder="Enter Product Dimension" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                    {productTemplateDataList && productTemplateDataList.map((item: any, idx: any) => (
                                        <Form.Group className="mb-3" controlId="productNameCtrlId">
                                            <Row>
                                                <Col md={3}>
                                                    <Form.Label>{item.attribute.name}</Form.Label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control type="text"
                                                        disabled={props.write}
                                                        value={item.value}
                                                        onChange={e => { handleInputChange(idx, e) }}
                                                        placeholder={'Enter ' + item.attribute.name} />
                                                    <Form.Text className="text-muted"></Form.Text>
                                                </Col>
                                            </Row>
                                        </Form.Group>))}
                                        

                                        
                                <Row className="justify-content-end">
                                    <Col className="text-end">
                                    <Button type="button" variant="danger" className="my-4 mx-2" onClick={goBack} >
                                                Back
                                            </Button>

                                        <Button type="button" className="btn btn-primary my-4 mx-2"
                                            onClick={submitProductSpecification}
                                        >Next</Button>

                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                    </Container>
                </Col>

            </Row>

        </Container>

       
    </>
}
export default EditProductSpecification;