import { useEffect, useState } from "react";
import FeatureApiService from "../../../data/services/feature/feature-api-service";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import RoleApiService from "../../../data/services/role/role-api-service";
import RolePermissionApiService from "../../../data/services/permission-api-service/permission-api-service";

const AddRolePermission = () => {
    const featureApiService: FeatureApiService = new FeatureApiService();
    const roleApiService: RoleApiService = new RoleApiService();
    const permissionApiService: RolePermissionApiService = new RolePermissionApiService();

    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ADD ROLE PERMISSION'));
    const [name, setName] = useState('')
    const [roleData, setRoleData] = useState<any>([]);
    const [featureData, setFeatureData] = useState<any>([]);
    const [featureId, setFeatureId] = useState('')
    const [roleId, setRoleId] = useState('')
    const [permissionData, setPermissionData] = useState<any>([])

    let navigate: any = useNavigate()

    const handleChange = (id: any) => {
        setFeatureId(id);
        getPermissionByFeatureId(id)

    };
    const getPermissionByFeatureId = async (id: any) => {
        try {
            let data = await featureApiService.getPermissionByFeatureId(id);
            setPermissionData(data);
            console.log('getPermissionByFeatureId data', data);
        } catch (error) {
            console.log(error);
        }

    }

    const handleRoleChange = (id: any) => {
        setRoleId(id);
        getFeatureByRoleId(id)
    }
    const getFeatureByRoleId = async (id: any) => {
        try {
            let data = await featureApiService.getFeatureByRoleId(id);
            setFeatureData(data);
            console.log('getFeatureByRoleId data', data);
        } catch (error) {
            console.log(error);
        }

    }

    const handleCheckboxChange = (idx: number, isChecked: boolean) => {
        setPermissionData((prevData: any) => {
            const newData = [...prevData];
            newData[idx].visibility = isChecked;
            return newData;
        });
    };

    const saveRolePermission = async (event: any) => {
        try {
            event.preventDefault();
            if (!roleId) {
                toast.error("Please Select Role", { containerId: 'TR' });
                return;
            }
            if (!featureId) {
                toast.error("Please Select Feature", { containerId: 'TR' });
                return;
            }

            let payload: any = {
                featureId: featureId,
                permissions: permissionData.map(({ id }: any) => ({ id }))
            }
            console.log(payload)
            await permissionApiService.createRolePermission(roleId, payload);
            toast.success('Role Permission Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/permission-datatable');
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Feature Create Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    // const getFeatureData = async () => {
    //     try {
    //         let result = await featureApiService.getAllFeature();
    //         console.log("getFeatureData", result);
    //         setFeatureData(result);
    //     } catch (error) {
    //         console.log("getFeatureData Error");
    //         console.log(error);
    //     }
    // }
    const getRoleData = async () => {
        try {
            let result = await roleApiService.getAllRole();
            console.log("getRoleData", result);
            setRoleData(result);
        } catch (error) {
            console.log("getRoleData Error");
            console.log(error);
        }
    }
    const goBack = () => {
        navigate('/main-layout/permission-datatable');
    }

    useEffect(() => {
        // getFeatureData();
        getRoleData();
    }, [])
    return <>
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add">
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Role</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <select className="form-control" value={roleId} onChange={(e) => { handleRoleChange(e.target.value) }} >
                                                    <option value="">-- Select Role --</option>
                                                    {roleData.map((data: any, idx: any) => (
                                                        <option key={idx} value={data.id}>{data.name}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Feature</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <select className="form-control" value={featureId} onChange={(e) => { handleChange(e.target.value) }} >
                                                    <option value="">-- Select Feature --</option>
                                                    {featureData.map((data: any, idx: any) => (
                                                        <option key={idx} value={data.feature.id}>{data.feature.name}</option>
                                                    ))}

                                                </select>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    {permissionData.length > 0 && (
                                        <h5>Role Permissions</h5>
                                    )}
                                    <Col className="d-flex flex-wrap">
                                        {permissionData && permissionData.map((item: any, idx: any) => (
                                            <Form.Group className="mb-3">

                                                <Row>
                                                    <Form.Check
                                                        checked={item.visibility}
                                                        type="checkbox"
                                                        label={item.actionName}
                                                        onChange={(e) => handleCheckboxChange(idx, e.target.checked)}
                                                    />
                                                </Row>
                                            </Form.Group>
                                        ))}
                                    </Col>

                                    <Form.Group>
                                        <Row >
                                            <Col xs={8} className="d-flex justify-content-end mt-3">
                                                <Button type="button" variant="danger" className="mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" onClick={saveRolePermission}> &nbsp;Save Changes&nbsp;</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>
                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
}
export default AddRolePermission