import { faPlus, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormHeader from "../../../components/form-header/form-header";
import VariantRuleApiService from "../../../data/services/variant-rule/variant-rule-api-service";
import variantTypeApiService from "../../../data/services/variant-type/variant-type-api-service";

const VariantRuleDatatable = () => {
    const variantRuleApiService: VariantRuleApiService = new VariantRuleApiService();
    const [variantRuleData, setVariantRule] = useState<any>([]);
    let navigate: any = useNavigate();

    const getVarientRuleData = async () => {
        try {
            let result = await variantRuleApiService.getAllVariantRules();
            console.log("getVarientTypeData", result);
            setVariantRule(result);
        } catch (error) {
            console.log("getVarientRuleData Error");
            console.log(error);
        }
    }

    const addVarientRule = () => {
        navigate('/main-layout/add-variant-rule')
    }

    const updateVarientRule = (id: any) => {
        navigate('/main-layout/edit-variant-rule', { state: { id: id } });
    }

    const deleteVariantRule = async (id: any) => {
        console.log('VariantRule Id=' + id);
        try {

            await variantRuleApiService.deleteVariantRule(id);
            toast.success('VariantRule Deleted Successfully', { containerId: 'TR' });
            await getVarientRuleData();
            navigate('/main-layout/variant-rule-datatable');
            return;

        } catch (error) {
            console.log("ERROR occured deleteAttribute() error=== " + JSON.stringify(error));
        }
    }
    useEffect(() => {
        getVarientRuleData();
    }, []);

    return <>
        <FormHeader title="Variant Rule Datatable" />
        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="card">
                        <Col className="card-header">
                            <button type="button" onClick={addVarientRule}
                                className="btn btn-primary add-row mt-md-0 mt-2">Add
                                Varient Rule</button>
                        </Col>

                        <Card.Body className="card-body p-4">
                            <Col className="table-responsive table-desi">
                                <Table striped bordered hover variant="white">
                                    <thead >
                                        <tr>
                                            <th>Name</th>
                                            <th>Variant Types</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {variantRuleData.map((data: any, idx: any) => (
                                            <tr>
                                                <td data-fiels="name">{data.name}</td>
                                                <td data-field="variantType">{data.combinationCount}</td>
                                                <td className="action">
                                                    <a onClick={() => updateVarientRule(data.id)}>
                                                        <FontAwesomeIcon icon={faPenToSquare} title="Edit Variant Rule" className='me-2 font-success' />
                                                    </a>
                                            
                                                    <a onClick={() => deleteVariantRule(data.id)}>
                                                            <FontAwesomeIcon icon={faTrash} title="delete Variant Type" className='me-2 font-success' />
                                                        </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
}
export default VariantRuleDatatable