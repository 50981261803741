import Table from 'react-bootstrap/Table';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faCirclePlus, faEye, faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import { confirmAlert } from "react-confirm-alert";
import BaseProductApiService from '../../data/services/base-product-api-service/base-product-api-service';
import { useDispatch } from 'react-redux';
import { clearFormHeaderAction, saveFormHeaderAction } from '../../data/state/form-header-action';
import { hasAccessPrivilege } from '../../account/helper/access-privilege';
import { UserContextData, UserContext } from '../../context/user-context';


const BaseProductDataTable = () => {
    const baseProductApiService: BaseProductApiService = new BaseProductApiService;
    const [baseProductData, setBaseProductData] = useState<any>([]);
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('BASE PRODUCTS'));
    const userContextData: UserContextData = useContext<UserContextData>({...UserContext});
    const [hasAddBaseProductPrivilege, setHasAddBaseProductPrivilege] = useState(false);
    const [hasEditBaseProductPrivilege, setHasEditBaseProductPrivilege] = useState(false);
    const [hasDeleteBaseProductPrivilege, setHasDeleteBaseProductPrivilege] = useState(false);
    const [hasViewBaseProductPrivilege, setHasViewBaseProductPrivilege] = useState(false);


    const getBaseProductData = async () => {
        try {
            let result = await baseProductApiService.getAllBaseProduct();
            console.log("getCategoriesData", result);
            setBaseProductData(result.items);
        } catch (error: any) {
            console.log(error);
            console.log("getCategoriesData Error");
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }
    const addCategories = () => {
        navigate('/main-layout/add-base-product')
    }

    const editBaseProduct = (id: any) => {
        navigate('/main-layout/edit-base-product', { state: { id: id } });
    }

    const deleteBaseProduct = async (data: any) => {
        console.log('VariantType Id=' + data.id);
        try {
            await baseProductApiService.deleteBaseProduct(data.id);
            toast.success('BaseProduct Deleted Successfully', { containerId: 'TR' });
            await getBaseProductData();
        } catch (error: any) {
            console.log("Categories Create Failed", error);
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Categories Create Failed", error);
            toast.error(error, { containerId: 'TR' });
        }

    }

    const unlinkBaseProduct = async (data: any) => {
        console.log('VariantType Id=' + data.id);
        try {
            await baseProductApiService.unlinkBaseProduct(data.id);
            toast.success('BaseProduct unlinked Successfully', { containerId: 'TR' });
            await getBaseProductData();
        } catch (error: any) {
            console.log("Categories Create Failed", error);
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Categories Create Failed", error);
            toast.error(error, { containerId: 'TR' });
        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const confirmDelete = (data: any) => {
        confirmAlert({
            title: "Confirm",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteBaseProduct(data),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const checkPermission = () => {
        const editBaseProductPrivilege = hasAccessPrivilege(userContextData, "BASE_PRODUCT", "EDIT_BASE_PRODUCT");
        setHasEditBaseProductPrivilege(editBaseProductPrivilege);
        const deleteBaseProductPrivilege = hasAccessPrivilege(userContextData, "BASE_PRODUCT", "DELETE_BASE_PRODUCT");
        setHasDeleteBaseProductPrivilege(deleteBaseProductPrivilege);
        const viewBaseProductPrivilege = hasAccessPrivilege(userContextData, "BASE_PRODUCT", "VIEW_BASE_PRODUCT");
        setHasViewBaseProductPrivilege(viewBaseProductPrivilege);
        const addBaseProductPrivilege = hasAccessPrivilege(userContextData, "BASE_PRODUCT", "ADD_BASE_PRODUCT");
        setHasAddBaseProductPrivilege(addBaseProductPrivilege);
    }

    useEffect(() => {
        getBaseProductData();
        checkPermission();
    }, []);


    return <>
        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="card">
                        <Col className="card-header">
                        { hasAddBaseProductPrivilege && 
                        <Button type="button" onClick={addCategories}
                            className="btn btn-primary add-row mt-md-0 mt-2">
                            <FontAwesomeIcon icon={faCirclePlus} />
                            &nbsp;Add New
                        </Button>
                        }
                            
                        </Col>
                        <Card.Body className="card-body p-4">
                            <Col >
                                <Table className=' all-package table-category' striped variant="white">
                                        <thead className='sticky-header' >
                                            <tr>
                                                <th>Name</th>
                                                <th>Code </th>
                                                <th>Variants</th>
                                                {
                            (hasEditBaseProductPrivilege || hasDeleteBaseProductPrivilege) && <th>Actions</th>
                        }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {baseProductData.map((data: any, idx: any) => (
                                                <tr>
                                                    <td>{data.name}</td>
                                                    <td>{data.code}</td>
                                                    <td>{data.variantNames} </td>
                                                    {
                                                        (hasViewBaseProductPrivilege || hasDeleteBaseProductPrivilege) && (
                                                    <td>
                                                        {hasViewBaseProductPrivilege && 
                                                        <a href="javascript:void(0)" onClick={() => editBaseProduct(data.id)} className="cursor-pointer">
                                                            <FontAwesomeIcon icon={faPenToSquare} title="edit base product" className='me-2 mx-2 font-success' />
                                                        </a>
                                                        }
                                 
                                                      {hasDeleteBaseProductPrivilege &&  <a
                                                        href="javascript:void(0)"
                                                        onClick={() => confirmDelete(data)}
                                                        className={`cursor-pointer ${data.linked_with_product ? 'unselectable' : ''}`}
                                                        style={{ pointerEvents: data.linked_with_product ? 'none' : 'auto' }}
                                                        >
                                                        <FontAwesomeIcon icon={faTrash} title="delete base product" className="me-2 delete-btn" />
                                                        </a>
                                                        }

                                                    </td>
                                                        )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
}

export default BaseProductDataTable 