import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Image } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import ProductApiService from "../../data/services/product/product-api-service";
import Paginator from "../../common/pagenator/pagenator";

const ProductGridView = () => {

    let navigate: any = useNavigate();
    const productApiService: ProductApiService = new ProductApiService;
    const [productData, setProductData] = useState<any>([]);
    const [totalPages, setTotalpages] = useState(0);
    const [activePage, setActivePage] = useState(0);
    const [page, setPage] = useState(1);

    const updateProduct = (id: any) => {
        navigate('/main-layout/edit-product', { state: { id: id } });
    }

    const getAllProductData = async (pageNo?: number) => {
        try {
            let url = "?page=" + page + "&pageSize=" + 40;

            if (pageNo) {
                url = "?page=" + pageNo + "&pageSize=" + 40;
            }
            let result = await productApiService.getAllProduct(url);
            console.log("getDimensionData", result);
            paginationSetup(result.meta);
            setProductData(result.items);
        } catch (error) {
            console.log("getDimensionData Error");
            console.log(error);
        }
    }

    const paginationSetup = (meta: any) => {
        setTotalpages(meta.totalPages);
        setActivePage(meta.currentPage);
    }

    const onPageClickCallback = (pageNo: number) => {
        getAllProductData(pageNo);
    }

    useEffect(() => {
        getAllProductData(1);
    }, []);

    return <>
        {productData.map((data: any, idx: any) => (
            <Col lg={3} className="products-admin ratio_asos" >
                <Col >
                    <Card>
                        <Card.Body className="product-box p-0">
                            <Col className="img-wrapper">
                                <Col className="front">
                                    <a href="javascript:void(0)">
                                        <Image src={data.defaultImageUrl}
                                            className="img-fluid lazyload bg-img" alt="" />
                                    </a>
                                    <Col className="product-hover">
                                        <ul>
                                            <li>
                                                <Button className="btn" type="button" data-original-title="" onClick={() => updateProduct(data.productId)}
                                                    title="Edit"> <FontAwesomeIcon icon={faPenToSquare} className='me-2 font-success' /></Button>
                                            </li>
                                            {/* <li>
                                                <button className="btn"><i className="ti-trash"></i></button>
                                            </li> */}
                                        </ul>
                                    </Col>
                                </Col>
                            </Col>
                            <Col className="product-detail">
                                <h6>{data.name}</h6>
                                {/* <h4>{product.moq}</h4> */}
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>

            </Col>
        ))}
        <Paginator totalPages={totalPages} onPageClick={onPageClickCallback} activePage={activePage}></Paginator>

    </>
}
export default ProductGridView 