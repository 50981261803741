import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import AccountApiService from "../../../data/services/account/account-api-service";
import { CreateAccountPayload } from "../../../data/services/account/create-account-payload";
import validator from 'email-validator';
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";


const roleType = [
    {
        name: 'Admin',
        code: 'ADMIN'
    },
    {
        name: 'Standard User',
        code: 'STANDARD_USER'
    }
]
const CreateAccount = () => {
    const accountApiService: AccountApiService = new AccountApiService();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('CREATE ACCOUNT'));
    const [userName, setUserName] = useState<any | null>(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mobile, setMobile] = useState('')
    const [roleCode, setRoleCode] = useState('')
    const [roleData, setRoleData] =  useState<any>([]);
    let navigate: any = useNavigate()

    const handleChange = (code: any) => {
        setRoleCode(code);
    };

    const goBack = () => {
        navigate('/main-layout/account-datatable');
    }
    const handleContactNumberChange = (event: any) => {
        const inputValue = event.target.value;
        const cleanedValue = inputValue.replace(/\D/g, '').slice(0, 20);
        setMobile(cleanedValue);
    };
    const createAccount = async (event:any) => {
        try {
            event.preventDefault();
            if (!userName) {
                toast.error("Please Enter Email", { containerId: 'TR' });
                return;
            }
            if (!firstName) {
                toast.error("Please Enter  First Name ", { containerId: 'TR' });
                return;
            }
            if (!lastName) {
                toast.error("Please Enter  Last Name ", { containerId: 'TR' });
                return;
            }
            if (!roleCode) {
                toast.error("Please Select  Role ", { containerId: 'TR' });
                return;
            }
            
            let payload: CreateAccountPayload = {
                userName: userName,
                firstName: firstName,
                lastName: lastName,
                roleCode: roleCode,
                mobile: mobile,
                sendEmail: true
            }
            await accountApiService.createAccount(payload);
            toast.success('Account Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/account-datatable');
        } catch (error: any) {
            console.log("Country Error")
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    }
    const fetchTemplateData = async () => {
        try{
            let response:any = await accountApiService.templateData();
            setRoleData(response);
        }catch (error: any){
            console.log("template error");
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    }

     useEffect (() => {
        fetchTemplateData();
    }, []);
    

    return <>
      <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                            <Form noValidate className="needs-validation user-add" onSubmit={createAccount}>
                                <Form.Group className="mb-3" controlId="productNameCtrlId">
                                <Row>
                                            <Col md={2}>
                                                <Form.Label>First Name <span className="required">*</span></Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" value={firstName}
                                                    autoComplete="off"
                                                    placeholder="Enter Your First Name"
                                                    onChange={e => { setFirstName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                        </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Last Name <span className="required">*</span></Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" 
                                                    value={lastName}
                                                    placeholder="Enter Your Last Name"
                                                    autoComplete="off"
                                                    onChange={e => { setLastName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                <Row>
                                            <Col md={2}>
                                                <Form.Label>Email <span className="required">*</span></Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                     <Form.Control
                                                        type="email"
                                                        isInvalid={userName && !validator.validate(userName)}
                                                        value={userName}
                                                        placeholder="Enter Your Email"
                                                        onChange={(event) => setUserName(event.target.value)}                        
                                                    />
                                            </Col>

                                        </Row>
                                        </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Mobile </Form.Label>
                                            </Col>
                                            <Col md={6}>
                                            <Form.Control
                                                type="number"
                                                value={mobile}
                                                placeholder="Enter Your Number"
                                                onChange={handleContactNumberChange}
                                                maxLength={10}
                                                required
                                                autoComplete="off"
                                            />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>

                                                <Form.Label>Role <span className="required">*</span></Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <select className="form-control" value={roleCode} onChange={(e) => { handleChange(e.target.value) }} >
                                                    <option value="">Select Role</option>
                                                    {roleData.map((data: any, idx: any) => (
                                                        <option key={idx} value={data.roleCode}>{data.name}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row>Note: Default password will send an email</Row>
                                    </Form.Group>
                                    <Form.Group>
                                    <Row >
                                        <Col xs={8} className="d-flex justify-content-end mt-3">
                                        <Button type="button" variant="danger" className="mx-2" onClick={goBack} >
                                                Cancel
                                            </Button> 
                                            <Button type="submit" variant="primary" > &nbsp;Save&nbsp;</Button>
                                               
                                       </Col>
                                    </Row>

                                </Form.Group>

                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
        </>
}
export default CreateAccount