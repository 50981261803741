import { useEffect, useState } from "react";
import BannerImageApiService from "../../data/services/banner-image/banner-image-api-service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "react-bootstrap";

const BannerImageDatatable = () => {
    const bannerImageApiService: BannerImageApiService = new BannerImageApiService();
    const [bannerImageData, setBannerImageData] = useState<any>([]);
    let navigate: any = useNavigate();

    const getBannerImageData = async () => {
        try {
            let result = await bannerImageApiService.getAllBannerImage();
            console.log("getBannerImageData", result);
            setBannerImageData(result);
        } catch (error) {
            console.log("getBannerImageData Error");
            console.log(error);
        }
    }

    const addBannerImage = () => {
        navigate('/main-layout/add-banner-image')
    }

    const updateBannerImage = (id: any) => {
        navigate('/main-layout/edit-banner-image', { state: { id: id } });
    }

    const deleteBannerImage = async (id: any) => {
        console.log('BannerImage Id=' + id);
        try {

            await bannerImageApiService.deleteBannerImage(id);
            toast.success('BannerImage Deleted Successfully', { containerId: 'TR' });
            await getBannerImageData();
            navigate('/main-layout/banner-image-datatable');
            return;

        } catch (error) {
            console.log("ERROR occured deleteBannerImage() error=== " + JSON.stringify(error));
        }
    }

    useEffect(() => {
        getBannerImageData();
    }, []);
    return <>
        <div className="container-fluid">
            <div className="page-header">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="page-header-left">
                            <h3>BannerImage
                                <small>Biobox Admin panel</small>
                            </h3>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <button type="button" onClick={addBannerImage}
                                className="btn btn-primary add-row mt-md-0 mt-2">Add
                                Banner Image</button>
                        </div>

                        <div className="card-body p-4">
                            <div className="table-responsive table-desi">
                                <table className="table all-package table-category " id="editableTable">
                                    <Table striped bordered hover variant="white">
                                        <thead >
                                            <tr>
                                                <th>Sort Order</th>
                                                <th>Title</th>
                                                <th>Sub Title</th>
                                                <th>Banner Image</th>
                                                <th>Option</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bannerImageData.map((data: any, idx: any) => (
                                                <tr>

                                                    <td data-field="sortOrder">{data.sortOrder}</td>
                                                    <td data-field="title">{data.title}</td>
                                                    <td data-field="sortOrder">{data.sortOrder}</td>
                                                    <td data-field="bannerImage"><img src={data.imageURL} /></td>
                                                    <td>
                                                        <a onClick={() => updateBannerImage(data.id)}>
                                                            <FontAwesomeIcon icon={faPenToSquare} className='me-2 font-success' />
                                                        </a>

                                                        <a onClick={() => deleteBannerImage(data.id)}>
                                                            <FontAwesomeIcon icon={faTrash} className='font-danger' />
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default BannerImageDatatable