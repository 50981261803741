
import { useEffect, useState } from "react";
import { Col, Container, Tab, Tabs } from "react-bootstrap";
import ProductBasicDetails from "./components/product-basic-details";
import ProductSpecificiation from "./components/product-specification";
import Card from 'react-bootstrap/Card';
import LinkVariant from "./components/link-variant";
import FormHeader from "../../components/form-header/form-header";
import AssociatedProducts from "./components/associated-products";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../data/state/form-header-action";

const AddProduct = () => {

    const [activeStep, setActiveStep] = useState(1);
    const [productFormData, setProductFormData] = useState<any>(new FormData());
    const [productType, setProducType] = useState<any>('NORMAL');
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ADD PRODUCT'));

    const onNextTab = () => {
        setActiveStep(activeStep + 1);
    }

    const onPreviousTab = () => {
        setActiveStep(activeStep - 1);
    }

    const onProductTypeChangeListener = (productType:string) => {
        if(productType == 'NORMAL') {
            if (productFormData.has('baseProductId')) {
                productFormData.delete('baseProductId');
            } 
            if (productFormData.has('variants')) {
                productFormData.delete('variants');
            }  
        }
        setProducType(productType);
    }

    useEffect(() => {
        
    }, [productType]);

    return <>
        {/* <FormHeader title="Add Product" /> */}
        <Container>
            <Card className="p-5">
                <Tabs defaultActiveKey="1" variant="pills"  activeKey={activeStep} style={{ pointerEvents: 'none' }}>
                    <Tab eventKey="1" title="Basic Info" >
                        <ProductBasicDetails onProductTypeChangeListener={onProductTypeChangeListener} productFormData={productFormData} onNextCallback={onNextTab} />
                    </Tab>

                    {productType == 'VARIANT' &&
                        <Tab eventKey="2" title="Link Variant" >
                            <LinkVariant productFormData={productFormData} onPreviousCallback={onPreviousTab} onNextCallback={onNextTab} />
                        </Tab>
                    }

                    <Tab eventKey={productType == 'VARIANT' ? '3': '2'} title="Product Details">
                        <ProductSpecificiation productFormData={productFormData} onPreviousCallback={onPreviousTab} onNextCallback={onNextTab} />
                    </Tab>

                    <Tab eventKey={productType === 'VARIANT' ? '4' : '3'} title="Associated Products">
                        <AssociatedProducts productFormData={productFormData} onPreviousCallback={onPreviousTab} onNextCallback={onNextTab} />
                    </Tab>
                    
                </Tabs>
            </Card>
        </Container>
    </>
}
export default AddProduct