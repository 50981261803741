
import { useContext, useEffect, useState } from "react";
import ProductApiService from "../../data/services/product/product-api-service";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import FormHeader from "../../components/form-header/form-header";
import EditProductBasicDetails from "./components/edit-product/edit-product-basic-details";
import EditLinkVariant from "./components/edit-product/edit-link-variant";
import EditProductSpecification from "./components/edit-product/edit-product-specification";
import { useLocation } from "react-router-dom";
import EditAssociatedProducts from "./components/edit-product/edit-associated-products";
import { faPenToSquare, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext, UserContextData } from "../../context/user-context";
import { hasAccessPrivilege } from "../../account/helper/access-privilege";

const EditProduct = (props: any) => {
    const productApiService: ProductApiService = new ProductApiService();
    const [activeStep, setActiveStep] = useState(1);
    const [productFormData, setProductFormData] = useState<any>(new FormData());
    const { state } = useLocation();
    const id = props.productId ?? (state ? state.id : null);
    const [data, setData] = useState<any>({});
    const [productType, setProducType] = useState<any>('');
    const [readAndWrite, setReadAndWrite] = useState(true);
    const userContextData: UserContextData = useContext<UserContextData>({...UserContext});
    const [hasEditProductPrivilege, setHasEditProductPrivilege] = useState(false);


    const onNextTab = () => {
        setActiveStep(activeStep + 1);
    }

    const onPreviousTab = () => {
        setActiveStep(activeStep - 1);
    }

    const handleReadAndWrite = () => {
        setReadAndWrite(false);
    }

    const handleReadOnly = () => {
        setReadAndWrite(true);
    }

    const getProductById = async () => {
        try {
            let data = await productApiService.getProductById(id);
            setData(data);
            setProducType(data.productType.toUpperCase());
            console.log("getProductById", data)
        } catch (error) {
            console.log("getProductById Error");
            console.log(error);
        }
    }

    const onProductTypeChangeListener = (type: string) => {
        if (type == 'NORMAL') {
            if (productFormData.has('baseProductId')) {
                productFormData.delete('baseProductId');
            }
            if (productFormData.has('variants')) {
                productFormData.delete('variants');
            }
        }
        setProducType(type);
    }

    const checkPermission = () => {
        const editProductPrivilege = hasAccessPrivilege(userContextData, "PRODUCT", "EDIT_PRODUCT");
        setHasEditProductPrivilege(editProductPrivilege);
      }

    useEffect(() => {
        getProductById();
        checkPermission();
    }, []);

    return <>

        <Row className="row">
            <Col md={10} className="page-header-left">
                <h3>{data.name}</h3>
            </Col>
           {hasEditProductPrivilege &&  readAndWrite && <Col md={2}>
                <Button
                    onClick={() => handleReadAndWrite()}
                    className="btn btn-primary add-row mt-md-0 mx-2"
                >
                    <FontAwesomeIcon icon={faPenToSquare} />&nbsp;
                    Edit
                </Button>

            </Col>}
            { hasEditProductPrivilege &&  !readAndWrite && <Col md={2}>
                <Button
                    disabled={!readAndWrite}
                    onClick={() => handleReadAndWrite()}
                    variant="danger"                >
                    <FontAwesomeIcon icon={faPenToSquare} />&nbsp;
                    Edit
                </Button>

            </Col>}

            {/* { !readAndWrite && <Col md={2}>
                <a onClick={() => handleReadOnly()} className="cursor-pointer">
                    <FontAwesomeIcon icon={faLock} size="2xl" title="Close Editing" className='me-2 font-success' />
                </a>
            </Col>} */}

        </Row>
        <Container>
            <Card >
                <Tabs defaultActiveKey="1" variant="underline" activeKey={activeStep} style={{ pointerEvents: 'none' }}>
                    <Tab eventKey="1" title="Basic Info">
                        <EditProductBasicDetails onProductTypeChangeListener={onProductTypeChangeListener} productId={id} productFormData={productFormData} onNextCallback={onNextTab} handleClose={props.handleClose} data={data} write={readAndWrite} />
                    </Tab>
                    {productType == 'VARIANT' &&
                        <Tab eventKey="2" title="Link Variant">
                            <EditLinkVariant productId={id} productFormData={productFormData} data={data} onPreviousCallback={onPreviousTab} onNextCallback={onNextTab} write={readAndWrite} />
                        </Tab>
                    }
                    <Tab eventKey={productType == 'VARIANT' ? '3' : '2'} title="Product Details">
                        <EditProductSpecification productId={id} productFormData={productFormData} data={data} onPreviousCallback={onPreviousTab} onNextCallback={onNextTab} id={id} write={readAndWrite} />
                    </Tab>

                    <Tab eventKey={productType == 'VARIANT' ? '4' : '3'} title="Associated Products">
                        <EditAssociatedProducts productId={id} productFormData={productFormData} onPreviousCallback={onPreviousTab} onNextCallback={onNextTab} handleClose={props.handleClose} write={readAndWrite} />
                    </Tab>

                </Tabs>
            </Card>
        </Container>
    </>
}
export default EditProduct