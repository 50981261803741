import HttpClientWrapper from "../../api/http-client-wrapper";

class AccountApiService{
    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }
    createAccount = async (payload:any) => {
        try {
            let data: any = await this.httpClientWrapper.post('v1/accounts', payload);
            console.log("AccountApiService createAccount() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    sendPassword = async (userName :any ) => {
        try {
            let data: any = await this.httpClientWrapper.put('v1/accounts/'+ userName + '/sendPassword');
            console.log("AccountApiService createAccount() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAccount = async (searchQuery?:string) => {
        try {
            let data: any = await this.httpClientWrapper.get('v1/accounts');
            console.log("BaseProductApiService getAllBaseProducts() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAccountById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/accounts/' + id);
            console.log("AccountApiService getAccountById() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


    editAccount = async (payload:any ) => {
        try {
            let data: any = await this.httpClientWrapper.put('/v1/accounts/changePassword', payload);
            console.log("AccountApiService editAccount() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    updateAccount = async (id:any, payload:any ) => {
        try {
            let data: any = await this.httpClientWrapper.put('/v1/accounts/'+ id, payload);
            console.log("AccountApiService editAccount() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    delteAccount = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/accounts/' + id);
            console.log("AccountApiService delteAccount() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    templateData = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/accounts/templates/');
            console.log("AccountApiService templateData() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


}
export default AccountApiService