import { Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap"
import FeatureAction from "./feature-action"
import { faCirclePlus, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import FeatureApiService from "../../../data/services/feature/feature-api-service"
import axios, { AxiosError } from "axios"

const AddFeature = () => {
    const featureApiService: FeatureApiService = new FeatureApiService();

    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ADD FEATURE'));
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [featureOptions, setFeatureOptions] = useState<any[]>([]);
    const [featureData, setFeatureData] = useState<any>([]);
    const [parentId, setParentId] = useState('')

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    let navigate: any = useNavigate()

    const handleInputChange = (index: any, field: string, value: any) => {
        const newFeatureOptions = [...featureOptions];
        newFeatureOptions[index][field] = value;
        setFeatureOptions(newFeatureOptions);
    };

    const addFeatureNameField = () => {
        setFeatureOptions([...featureOptions, { actionName: '', actionCode: '', visibility: false }]);
    };

    const removeFeatureNameField = (index: any) => {
        const newFeatureOptions = [...featureOptions];
        newFeatureOptions.splice(index, 1);
        setFeatureOptions(newFeatureOptions);
    };

    const handleChange = (id: any) => {
        setParentId(id);
    };

    const saveFeature = async (event: any) => {
        try {
            event.preventDefault();
            if (!name) {
                toast.error("Please Enter name", { containerId: 'TR' });
                return;
            }

            // if (!featureOptions || featureOptions.length == 0) {
            //     toast.error("Please Enter Feature Action ", { containerId: 'TR' });
            //     return;
            // }

            let payload: any = {
                name: name,
                featureCode: code,
                parentId: parentId,
                // featureActions: featureOptions
            }
            await featureApiService.createFeature(payload);
            toast.success('Feature Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/feature-datatable');
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Feature Create Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const getFeatureData = async () => {
        try {
            let result = await featureApiService.getAllFeature();
            console.log("getFeatureData", result);
            setFeatureData(result);
        } catch (error) {
            console.log("getFeatureData Error");
            console.log(error);
        }
    }

    const goBack = () => {
        navigate('/main-layout/feature-datatable');
    }

    useEffect(() => {
        getFeatureData();
    }, [])
    return <>
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add">
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Name</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" value={name}
                                                    autoComplete="off"
                                                    onChange={e => { setName(e.target.value) }}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Code</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text"
                                                    value={code}
                                                    autoComplete="off"
                                                    onChange={e => { setCode(e.target.value) }}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>

                                                <Form.Label>Parent Feature</Form.Label>
                                            </Col>
                                            <Col md={6}>


                                                <select className="form-control" value={parentId} onChange={(e) => { handleChange(e.target.value) }} >
                                                    <option value="">-- Select Feature --</option>
                                                    {featureData.map((data: any, idx: any) => (
                                                        <option key={idx} value={data.id}>{data.name}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    {/* <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>

                                                <Form.Label>Feature Action</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                {featureOptions.map((data, index) => (
                                                    <Row key={index}>
                                                        <Col>
                                                            <Form noValidate className="needs-validation user-add">
                                                                <Form.Group className="mb-3" controlId="productNameCtrlId">
                                                                    <Row>
                                                                        <Col md={2}>
                                                                            <Form.Label>Name</Form.Label>
                                                                        </Col>
                                                                        <Col md={10}>
                                                                            <Form.Control
                                                                                type="text"
                                                                                value={data.actionName}
                                                                                autoComplete="off"
                                                                                onChange={(e) => handleInputChange(index, 'actionName', e.target.value)}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                                <Form.Group className="mb-3" controlId="categorySort">
                                                                    <Row>
                                                                        <Col md={2}>
                                                                            <Form.Label>Code</Form.Label>
                                                                        </Col>
                                                                        <Col md={10}>
                                                                            <Form.Control
                                                                                type="text"
                                                                                value={data.actionCode}
                                                                                autoComplete="off"
                                                                                onChange={(e) => handleInputChange(index, 'actionCode', e.target.value)}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                                <Form.Group className="mb-3">
                                                                    <Row>
                                                                        <Col md={2}>
                                                                            <Form.Label>Visibility</Form.Label>
                                                                        </Col>
                                                                        <Col md={10}>
                                                                            <Form.Check
                                                                                checked={data.visibility}
                                                                                type="checkbox"
                                                                                onChange={(e) => handleInputChange(index, 'visibility', e.target.checked)}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </Form>
                                                        </Col>
                                                        {index !== 0 && (
                                                            <Col md={1} className="mt-2">
                                                                <Button
                                                                    type="button"
                                                                    variant="danger"
                                                                    className="mx-2"
                                                                    onClick={() => removeFeatureNameField(index)}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                ))}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={2}>
                                            </Col>
                                            <Col md={4} className=" mt-3">
                                                <Button onClick={addFeatureNameField}><FontAwesomeIcon icon={faCirclePlus} />
                                                    &nbsp;Add New</Button>
                                            </Col>
                                        </Row>

                                    </Form.Group> */}


                                    <Form.Group>
                                        <Row >
                                            <Col xs={8} className="d-flex justify-content-end mt-3">
                                                <Button type="button" variant="danger" className="mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" onClick={saveFeature}> &nbsp;Save&nbsp;</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>

                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
}
export default AddFeature