import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FeatureApiService from "../../../data/services/feature/feature-api-service";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import RolePermissionApiService from "../../../data/services/permission-api-service/permission-api-service";

const EditRolePermission = () => {
    const featureApiService: FeatureApiService = new FeatureApiService();
    const permissionApiService: RolePermissionApiService = new RolePermissionApiService();
    const { state } = useLocation();
    const roleId = state?.id;
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('EDIT ROLE PERMISSION'));
    const [featureData, setFeatureData] = useState<any>([]);
    const [featureId, setFeatureId] = useState('')
    const [permissionData, setPermissionData] = useState<any>([]);
    const [rolePermissionId, setRolePermissionId] = useState('');

    let navigate: any = useNavigate()

    const handleChange = (id: any) => {
        setFeatureId(id);
        getPermissionByFeatureId(id);
    }
    const hilightSelectedActions = (data:any, updatedAction:any)  => {
        for(let i=0; i<updatedAction.length; i++){
            for(let j=0; j<data.length; j++){
                if(updatedAction[i].id == data[j].featureAction.id){
                    updatedAction[i].checked = true;
                }
            }
        }
        setPermissionData([...updatedAction]);
    }

    const getFeatureActionByRoleAndFeatureId = async (roleId:string,fId:string, actionData: any) => {
        try {
            let data = await permissionApiService.getFeatureActionByRoleAndFeatureId(roleId, fId);
            setRolePermissionId(data.rolePrivilege.id);
            hilightSelectedActions(data.rolePrivilegeAction, actionData);
            console.log('getFeatureActionByRoleAndFeatureId data', data);
        } catch (error) {
            console.log(error);
        }

    }
    const constructCheckedRow = (data:any, fId:any) => {
        for(let i=0; i<data.length; i++){
            data[i]['checked'] = false;
        }
        setPermissionData([...data]);
        getFeatureActionByRoleAndFeatureId(roleId, fId, data);

    }

    const getPermissionByFeatureId = async (fId:any) => {
        try {
            let data = await featureApiService.getPermissionByFeatureId(fId);
            constructCheckedRow(data, fId);
            console.log('getPermissionByFeatureId data', data);
        } catch (error) {
            console.log(error);
        }

    }

    const handleCheckboxChange = (idx: number, isChecked: boolean) => {
        setPermissionData((prevData: any) => {
            const newData = [...prevData];
            newData[idx].checked = isChecked;
            return newData;
        });
    };

    const constructRolePermissionPayload = () => {
        let payload : any [] = [];
        for(let i=0; i<permissionData.length; i++){
            if(permissionData[i].checked){
                let data :any = {};
                data.id = permissionData[i].id;
                payload.push(data);
            }
        }
        return payload;

    }

    const saveRolePermission = async (event: any) => {
        try {
            event.preventDefault();
            if (!featureId) {
                toast.error("Please Select Feature", { containerId: 'TR' });
                return;
            }
            let payload: any = {
                featureId: featureId,
                permissions: constructRolePermissionPayload()
            }
            console.log(payload);
            if(!rolePermissionId){
                await  permissionApiService.createRolePermission(roleId,payload);
            }else{
                await permissionApiService.updateRolePermission(roleId,rolePermissionId,payload);
            }
            toast.success('Role Permission Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/permission-datatable');
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Feature Create Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const getFeatureData = async () => {
        try {
            let result = await featureApiService.getAllFeature();
            console.log("getFeatureData", result);
            getFeatureDataByRoleId(result);
        } catch (error) {
            console.log("getFeatureData Error");
            console.log(error);
        }
    }

    const updateSelectedFeatures = (selectedFeatures: any[], featureData1: any) => {
        const updatedFeatureData = [...featureData1];
    
        for (let i = 0; i < updatedFeatureData.length; i++) { 
            updatedFeatureData[i]['checked'] = false;
            for (let j = 0; j < selectedFeatures.length; j++) {
                if (updatedFeatureData[i].id === selectedFeatures[j].feature.id) {
                    updatedFeatureData[i].checked = true;
                  
                }
            }
        }
        console.log("updatedFeatureData", updatedFeatureData);

        setFeatureData(updatedFeatureData); 
    }

    const getFeatureDataByRoleId = async (data:any) => {
        try {
            let result = await featureApiService.getFeatureByRoleId(roleId);
            console.log("getFeatureData", result);
            updateSelectedFeatures(result, data);
        } catch (error) {
            console.log("getFeatureData Error");
            console.log(error);
        }
    }

    const goBack = () => {
        navigate('/main-layout/permission-datatable');
    }

    useEffect(() => {
        getFeatureData();
    }, [])
    return <>
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add">
                                  
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Feature</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <select className="form-control" value={featureId} onChange={(e) => { handleChange(e.target.value) }} >
                                                    <option value="">-- Select Feature --</option>
                                                    {featureData.map((data: any, idx: any) => (
                                                        data.checked && ( <option key={idx} value={data.id}> {data.name}</option> )
                                                      
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    {permissionData.length > 0 && (
                                        <h5>Role Permissions</h5>
                                    )}                                    
                                    <Col className="d-flex flex-wrap">
                                        {permissionData && permissionData.map((item: any, idx: any) => (
                                            <Form.Group className="mb-3">

                                                <Row>
                                                    <Form.Check
                                                        checked={item.checked}
                                                        type="checkbox"
                                                        label={item.actionName}
                                                        onChange={(e) => handleCheckboxChange(idx, e.target.checked)}
                                                    />
                                                </Row>
                                            </Form.Group>
                                        ))}
                                    </Col>

                                    <Form.Group>
                                        <Row >
                                            <Col xs={8} className="d-flex justify-content-end mt-3">
                                                <Button type="button" variant="danger" className="mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" onClick={saveRolePermission}> &nbsp;Update Changes&nbsp;</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>
                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
}
export default EditRolePermission