import { useContext, useEffect, useState } from "react";
import EmailConfigApiService from "../../data/services/email-config-api-service/email-config-api-service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import { confirmAlert } from "react-confirm-alert";
import { Button, Card, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { faEye, faPenToSquare, faTrash, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormHeader from "../../components/form-header/form-header";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../data/state/form-header-action";
import { hasAccessPrivilege } from "../../account/helper/access-privilege";
import { UserContextData, UserContext } from "../../context/user-context";

const EmailConfigDataTable = () => {
    const emailConfigApiService: EmailConfigApiService = new EmailConfigApiService();
    const [emailConfigData, setEmailConfigData] = useState<any>([]);
    const [emailConfig, setEmailConfig] = useState<any>([]);
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('EMAIL CONFIGURATION'));
    const userContextData: UserContextData = useContext<UserContextData>({ ...UserContext });
    const [hasEditEmailConfigPrivilege, setHasEditEmailConfigPrivilege] = useState(false);
    const [hasDeleteEmailConfigPrivilege, setHasDeleteEmailConfigPrivilege] = useState(false);
    const [hasAddEmailConfigPrivilege, setHasAddEmailConfigPrivilege] = useState(false);

    const checkPermission = () => {
        const editEmailConfigPrivilege = hasAccessPrivilege(userContextData, "EMAIL_CONFIGURATION", "EDIT_EMAIL_CONFIG");
        setHasEditEmailConfigPrivilege(editEmailConfigPrivilege);
        const deleteEmailConfigPrivilege = hasAccessPrivilege(userContextData, "EMAIL_CONFIGURATION", "DELETE_EMAIL_CONFIG");
        setHasDeleteEmailConfigPrivilege(deleteEmailConfigPrivilege);
        const addEmailConfigPrivilege = hasAccessPrivilege(userContextData, "EMAIL_CONFIGURATION", "ADD_EMAIL_CONFIG");
        setHasAddEmailConfigPrivilege(addEmailConfigPrivilege);

    }

    const getAllEmailConfig = async () => {
        try {
            let result = await emailConfigApiService.getAllEmailConfig();
            console.log("getAllEmailConfig", result);
            setEmailConfigData(result);
            setEmailConfig(result)
        } catch (error) {
            console.log("getAllEmailConfig Error");
            console.log(error);
        }
    }
    const addEmailConfig = () => {
        navigate('/main-layout/add-email-config')
    }

    const editEmailConfig = (id: any) => {
        navigate('/main-layout/edit-email-config', { state: { id: id } });
    }

    const deleteEmailConfig = async (data: any) => {
        console.log('VariantType Id=' + data.id);
        try {
            await emailConfigApiService.deleteEmailConfig(data.id);
            toast.success('EmailConfig Deleted Successfully', { containerId: 'TR' });
            await getAllEmailConfig();
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("EmailConfig Create Failed", error);
            toast.error(error, { containerId: 'TR' });
        }

    }
    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const [on, setOn] = useState(false);
    const handleOn = () => setOn(true);
    const handleOff = () => setOn(false);

    const openDialog = (data: any) => {
        setOn(true);
        setEmailConfig(data);
    }

    const confirmDelete = (data: any) => {
        confirmAlert({
            title: "Confirm",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteEmailConfig(data),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    useEffect(() => {
        getAllEmailConfig();
        checkPermission();
    }, []);


    return <>
        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="card">
                        <Col className="card-header">
                            {hasAddEmailConfigPrivilege && <Button type="button" onClick={addEmailConfig}
                                className="btn btn-primary add-row mt-md-0 mt-2">
                                <FontAwesomeIcon icon={faCirclePlus} />
                                &nbsp;Add New
                            </Button>
                            }

                        </Col>
                        <Card.Body className="card-body p-4">
                            <Col className="table-responsive table-desi">
                                <Table className=' all-package table-category' striped variant="white">
                                    <thead >
                                        <tr>
                                            <th>Location</th>
                                            <th>Code </th>
                                            {
                                                (hasEditEmailConfigPrivilege || hasDeleteEmailConfigPrivilege) && <th>Actions</th>
                                            }                                        </tr>
                                    </thead>
                                    <tbody>
                                        {emailConfigData && emailConfigData.map((data: any, idx: any) => (
                                            <tr>
                                                <td data-field="name">{data.location.name}</td>
                                                <td data-field="code">{data.location.code}</td>
                                                {
                                                    (hasEditEmailConfigPrivilege || hasDeleteEmailConfigPrivilege) && (
                                                        <td>
                                                            {hasEditEmailConfigPrivilege &&
                                                                <a href="javascript:void(0)" onClick={() => editEmailConfig(data.id)} className="cursor-pointer">
                                                                    <FontAwesomeIcon icon={faPenToSquare} title="Edit Email Config" className='me-2 mx-2 font-success' />
                                                                </a>}
                                                            {hasDeleteEmailConfigPrivilege &&
                                                                <a href="javascript:void(0)" onClick={() => confirmDelete(data)} className="cursor-pointer">
                                                                    <FontAwesomeIcon icon={faTrash} title="Delete Email Config" className='me-2 mx-2 delete-btn' />
                                                                </a>}

                                                        </td>
                                                    )}
                                            </tr>
                                        ))}
                                    </tbody>

                                    <Modal size='lg' show={on} onHide={handleOff}>
                                        <Modal.Header closeButton>
                                            <h4 className="center">Email Details</h4>
                                        </Modal.Header>
                                        <table className="table all-package table-category " id="editableTable">
                                            <Table striped bordered hover variant="white">
                                                <thead >
                                                    <tr><th>No</th>
                                                        <th> Email</th>
                                                        <th>cc</th>

                                                    </tr>
                                                </thead>

                                            </Table>
                                        </table>
                                    </Modal>
                                </Table>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
}

export default EmailConfigDataTable