import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormHeader from "../../../components/form-header/form-header"
import VariantRuleApiService from "../../../data/services/variant-rule/variant-rule-api-service";
import VariantTypeApiService from "../../../data/services/variant-type/variant-type-api-service";
import { VariantRulePayload } from "../../../data/services/variant-rule/variant-rule-payload";

let variantDefaultData: any[] = [];
const EditVariantRule = () => {
    let navigate: any = useNavigate()
    const variantRuleApiService: VariantRuleApiService = new VariantRuleApiService();
    const variantTypeApiService: VariantTypeApiService = new VariantTypeApiService();
    const [name, setName] = useState('')
    const [variantRuleName, setVariantRuleName] = useState('')

    const [variantTypeData, setVariantTypeData] = useState<any[]>([]);
    const [combinations, setCombinations] = useState<any>([]);
    const { state } = useLocation();
    const id = state.id;

    const handleAddCombination = () => {
        //setCombinations([...combinations, { variantTypeId: '' }]);
        combinations.push({ variantTypeId: '' });
    };

    const addCombination = () => {
        let combination: any = {
            'variantTypeId': ''
        };
        setCombinations([...combinations, combination]);
    }
    const handleChange = (index: number, value: string) => {
        const updatedCombinations = [...combinations];
        updatedCombinations[index].variantTypeId = value;
        setCombinations(updatedCombinations);
    };

    const getVariantRuleById = async () => {
        try {
            let data = await variantRuleApiService.getVariantRuleById(id);
            variantRuleData(data);

            console.log("getAttributeById", data)
        } catch (error) {
            console.log("getAttributeById Error");
            console.log(error);
        }
    }
    const saveVariantType = async () => {
        if (!variantRuleName) {
            toast.error("Please Enter Rule name", { containerId: 'TR' });
            return;
        }
        try {
            let payload = {
                ruleName: variantRuleName,
                variantTypeIds: combinations
            }
            await variantRuleApiService.createVariantRule(payload);
            toast.success('Variant Rule Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/variant-rule-datatable')
        } catch (error: any) {
            console.log("Variant Rule Error")
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const getTemplateData = async () => {
        try {
            let data: any[] = await variantTypeApiService.getAllVariantType();
            variantDefaultData = [...data];
            createNewCombination();
        } catch (error) {
            console.log(error)
        }
    }

    const createNewCombination = () => {
        addCombination();
        const variantFilter: any = {};
        variantFilter.selected = '';
        variantFilter.items = [];
        for (let i = 0; i < variantDefaultData.length; i++) {
            variantFilter.items.push(variantDefaultData[i]);
        }
        variantTypeData.push(variantFilter);
        setVariantTypeData([...variantTypeData]);
    }

    const handleRemoveCombination = (selectedIdx: number) => {
        variantTypeData.splice(selectedIdx, 1);
        setVariantTypeData([...variantTypeData]);

        combinations.splice(selectedIdx, 1);
        setCombinations([...combinations]);
    };

    const updateVarientRule = async () => {
        try {
            let payload: VariantRulePayload = {
                name: name,
                combinationCount: combinations
            }
            await variantRuleApiService.editVariantRule(id, payload);
            toast.success('VariantRule Updated Successfully', { containerId: 'TR' });
            navigate('/main-layout/variant-rule-datatable')
        } catch (error: any) {
            console.log("Attribute Error")
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const variantRuleData = (data: any) => {
        setName(data.name);
        setCombinations(data.combinations);
    }

    const validateAttributeType = async () => {
        if (!name) {
            toast.error("Please Enter Name", { containerId: 'TR' });
            return;
        }
        if (!combinations) {
            toast.error("Please Enter VariantType", { containerId: 'TR' });
            return;
        }

        await updateVarientRule();
    }


    useEffect(() => {
        getTemplateData();
        getVariantRuleById();
    }, []);
    return <>
        <FormHeader title="Edit Variant Rule" />
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                    <Card className="my-5 p-4">
                        <Row>
                            <Form noValidate className="needs-validation user-add" onSubmit={handleAddCombination}>
                                <Form.Group className="mb-3" controlId="productNameCtrlId">
                                    <Row>
                                        <Col md={2}>
                                            <Form.Label>Variant Rule Name</Form.Label>
                                        </Col>
                                        <Col md={8}>

                                            <Form.Control
                                                className="w-75"
                                                value={name}
                                                onChange={e => { setName(e.target.value) }}
                                                type="text" required />
                                        </Col>
                                        <Form.Group className="mb-3" controlId="productNameCtrlId">
                                            <Row >
                                                <Col md={2}>
                                                    <Form.Label>Variant Type</Form.Label>
                                                </Col>
                                                <Col md={8}>
                                                    <Row>
                                                        <Col>
                                                            {variantTypeData.map((variantType, index: any) => (
                                                                <Row>
                                                                    <Col md={8}>
                                                                        <select className="form-control"
                                                                            onChange={(e) => { handleChange(index, e.target.value) }}>
                                                                            <option value="">Select VariantType</option>
                                                                            {variantType.items.map((item: any, idx: any) => (
                                                                                <option key={idx} value={item.id}>{item.variantType}</option>
                                                                            ))}
                                                                        </select>

                                                                    </Col>

                                                                    <Col >

                                                                        {variantType.items.length > 1 && <Button type="button" className="my-2 btn-danger mx-2" onClick={() => handleRemoveCombination(index)}>Remove</Button>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </Col>


                                                        <Col>
                                                            <Button type="button" className="my-2" onClick={createNewCombination}>Add RuleCombination</Button>

                                                        </Col>
                                                    </Row>

                                                    <Button type="button" className="btn btn-primary" onClick={validateAttributeType}>Save</Button>

                                                </Col>
                                            </Row>
                                        </Form.Group>

                                    </Row>
                                </Form.Group>

                            </Form>


                        </Row>
                        </Card>
                    </Container>

                </Col>
            </Row>
        </Container>
    </>
}
export default EditVariantRule