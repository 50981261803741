import { useContext, useEffect, useState } from "react";
import { faCirclePlus, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, CardBody, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { AxiosError } from 'axios';
import FormHeader from "../../components/form-header/form-header";
import BaseProductApiService from "../../data/services/base-product-api-service/base-product-api-service";
import VariantApiService from "../../data/services/variant/variant-api-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../data/state/form-header-action";
import { UserContextData, UserContext } from "../../context/user-context";
import { hasAccessPrivilege } from "../../account/helper/access-privilege";

let immutableVariants: any[] = [];

const EditBaseProduct = () => {

    const baseProductApiService: BaseProductApiService = new BaseProductApiService;
    const variantApiService: VariantApiService = new VariantApiService;
    const { state } = useLocation();
    const baseproductId = state.id;
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [variantData, setVariantData] = useState<any[]>([]);
    const [selectedVariants, setSelectedVariants] = useState<any[]>([]);
    let navigate: any = useNavigate();
    const [show, setShow] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState<any>();
    const [variantOptions, setVariantOptions] = useState<any[]>([]);
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('EDIT BASE PRODUCT'));
    const [readAndWrite, setReadAndWrite] = useState(true);
    const userContextData: UserContextData = useContext<UserContextData>({...UserContext});
    const [hasEditBaseProductPrivilege, setHasEditBaseProductPrivilege] = useState(false);

    const handleReadAndWrite = () => {
        setReadAndWrite(false);
    }

    const handleReadOnly = () => {
        setReadAndWrite(true);
    }


    const updateBaseProduct = async (event: any) => {
        try {
            event.preventDefault();
            let payload: any = {
                name: name,
                code: code,
                variants: constructVariantPayload(selectedVariants)
            }
            console.log(payload);
            await baseProductApiService.updateBaseProduct(baseproductId, payload);
            toast.success('BaseProduct Updated Successfully', { containerId: 'TR' });
            navigate('/main-layout/base-product-datatable');
        } catch (error: any) {
            console.log("BaseProduct Create Failed", error);

            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("BaseProduct Create Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const constructVariantPayload = (selectedVariants: any[]) => {
        const variants: any[] = [];
        for (let i = 0; i < selectedVariants.length; i++) {
            const variant: any = {};
            variant.id = selectedVariants[i].id;
            variant.sortOrder = selectedVariants[i].sortOrder;
            variant.options = constructVariantOptionPayload(selectedVariants[i].options);
            variants.push(variant);
        }
        return variants;
    }

    const constructVariantOptionPayload = (selectedVariantOptions: any[]) => {
        const options: any[] = [];
        for (let i = 0; i < selectedVariantOptions.length; i++) {
            const option: any = {};
            option.id = selectedVariantOptions[i].id || selectedVariantOptions[i].option.id;
            option.sortOrder = selectedVariantOptions[i].sortOrder;
            options.push(option);
        }
        return options;
    }

    const handleSortOrderChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*$/.test(inputValue) || inputValue === '') {
            setSortOrder(inputValue);
        }

    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    }


    const goBack = () => {
        navigate('/main-layout/base-product-datatable')
    }

    const getVariantData = async () => {
        try {
            let baseProdut: any = await baseProductApiService.getBaseProductById(baseproductId);
            updateUI(baseProdut);
            let data: any[] = await variantApiService.getAllVariant();
            for (let i = 0; i < data.length; i++) {
                immutableVariants.push(data[i]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateUI = (baseProduct: any) => {
        setName(baseProduct.name);
        setCode(baseProduct.code);
        const variants: any[] = [];
        for (let i = 0; i < baseProduct.baseProductVariants.length; i++) {
            const baseProductVariant: any = baseProduct.baseProductVariants[i];
            const variant: any = {};
            variant.baseProductVariantId = baseProductVariant.id;
            variant.id = baseProductVariant.variant.id;
            variant.sortOrder = baseProductVariant.sortOrder;
            variant.variantName = baseProductVariant.variant.variantName;
            variant.options = constructOptionsFromBaseProductResponse(baseProductVariant.options);
            variant.optionStr = joinAllOptions(variant.options);
            variants.push(variant);
        }
        setSelectedVariants([...variants]);
    }

    const joinAllOptions = (aOptions: any[]) => {
        const optionNames: any[] = [];
        for (let i = 0; i < aOptions.length; i++) {
            const baseProductVariantOption: any = aOptions[i];
            optionNames.push(baseProductVariantOption.option.option);
        }
        if (optionNames.length == 0) {
            return "";
        }
        return optionNames.join(",");
    }

    const constructOptionsFromBaseProductResponse = (aOptions: any[]): any[] => {
        const options: any[] = [];
        for (let i = 0; i < aOptions.length; i++) {
            const baseProductVariantOption: any = aOptions[i];
            const option: any = {};
            option.baseProductVariantOptionId = baseProductVariantOption.id;
            option.option = baseProductVariantOption.option;
            option.sortOrder = baseProductVariantOption.sortOrder;
            options.push(option);
        }
        return options;
    }

    const onVariantSelectedHandler = (e: any) => {
        const selectedVariantId = e.target.value;
        setSortOrder('');
        if (!selectedVariantId) {
            setSelectedVariant('');
            setVariantOptions([]);
            return;
        }
        const variant: any = variantData.find((v) => v.id === selectedVariantId);
        setSelectedVariant(variant);
        for (let i = 0; i < variant.options.length; i++) {
            variant.options[i].sortOrder = '';
            variant.options[i].disableSortOrder = true;
        }
        setVariantOptions(variant.options);
    };

    const handleAddClick = () => {
        try {
            if (!selectedVariant) {
                throw new Error("Please select variant");
            }
            if (!sortOrder) {
                throw new Error("Please enter variant sort order");
            }
            const variant: any = {};
            if (selectedVariant.baseProductVariantId) {
                variant.baseProductVariantId = selectedVariant.baseProductVariantId;
            }
            variant.id = selectedVariant.id;
            variant.variantName = selectedVariant.variantName;
            variant.sortOrder = sortOrder;
            const options: any[] = [];
            const optionsStr: any[] = [];
            for (let i = 0; i < variantOptions.length; i++) {
                if (variantOptions[i].checked) {
                    options.push(variantOptions[i]);
                    optionsStr.push(variantOptions[i].option);
                }
            }
            if (options.length == 0) {
                throw new Error("Please select variant option");
            } else {
                for (let i = 0; i < options.length; i++) {
                    if (!options[i].sortOrder) {
                        throw new Error("Please enter variant option sort order");
                    }
                }
            }
            variant.options = options;
            variant.optionStr = optionsStr.join(',');
            pushToDataTable(variant);
        } catch (error: any) {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const pushToDataTable = (variant: any) => {
        let idx = -1;
        for (let i = 0; i < selectedVariants.length; i++) {
            if (selectedVariants[i].id == variant.id) {
                idx = i;
                break;
            }
        }
        if (idx == -1) {
            selectedVariants.push(variant);
        } else {
            selectedVariants.splice(idx, 1, variant);
        }
        setSelectedVariants([...selectedVariants]);
        setShow(false);
    }

    const onVariantOptionSelected = (e: any, option: any) => {
        for (let i = 0; i < variantOptions.length; i++) {
            if (variantOptions[i].id == option.id) {
                variantOptions[i].checked = e.target.checked;
                variantOptions[i].disableSortOrder = !e.target.checked;
                if (!e.target.checked && variantOptions[i].sortOrder != '') {
                    variantOptions[i].sortOrder = '';
                }
            }
        }
        setVariantOptions([...variantOptions]);
    };



    const removeVariant = (index: any) => {
        selectedVariants.splice(index, 1);
        setSelectedVariants([...selectedVariants]);
    };

    const handleShow = () => {
        setSelectedVariant('');
        setVariantOptions([]);
        setSortOrder('');
        populateVariantData();
        setShow(true);
    }

    const onVariantOptionSortOrderChange = (index: number, event: any, option: any) => {
        variantOptions[index].sortOrder = event.target.value;
        setVariantOptions([...variantOptions]);
    };

    const populateVariantData = () => {
        const dialogVariants: any[] = [];
        for (let i = 0; i < immutableVariants.length; i++) {
            let isAlreadyAssigned: boolean = false;
            for (let j = 0; j < selectedVariants.length; j++) {
                if (immutableVariants[i].id == selectedVariants[j].id) {
                    isAlreadyAssigned = true;
                    break;
                }
            }
            if (!isAlreadyAssigned) {
                dialogVariants.push(immutableVariants[i]);
            }
        }
        setVariantData([...dialogVariants]);
    }

    const editBaseProductVariant = (item: any) => {
        item.disabled = true;
        const dialogVariants: any[] = [];
        dialogVariants.push(item);
        setVariantData([...dialogVariants]);
        setSelectedVariant(item);
        setSortOrder(item.sortOrder);
        const options: any[] = getEditOptions(item);
        setVariantOptions([...options]);
        setShow(true);
    }

    const getEditOptions = (item: any) => {
        const variantRow: any = immutableVariants.find((v) => v.id === item.id);
        for (let i = 0; i < variantRow.options.length; i++) {
            let isOptionSelected: boolean = false;
            for (let j = 0; j < item.options.length; j++) {
                const optionId = item.options[j].option.id || item.options[j].id;
                if (variantRow.options[i].id == optionId) {
                    variantRow.options[i].baseProductVariantOptionId = item.options[j].baseProductVariantOptionId;
                    isOptionSelected = true;
                    variantRow.options[i].sortOrder = item.options[j].sortOrder;
                    break;
                }
            }
            variantRow.options[i].checked = isOptionSelected;
            variantRow.options[i].disableSortOrder = !isOptionSelected;
        }
        return variantRow.options;
    }

    const handleClose = () => {
        setShow(false);
        setSelectedVariant([]);
    };


    const checkPermission = () => {
        const editBaseProductPrivilege = hasAccessPrivilege(userContextData, "BASE_PRODUCT", "EDIT_BASE_PRODUCT");
        setHasEditBaseProductPrivilege(editBaseProductPrivilege);
    }

    useEffect(() => {
        getVariantData();
        checkPermission();
        return () => {
            immutableVariants = [];
        };
    }, []);

    return <>
        <Col className="">
            <Container fluid>
                <Card>

                    <Row >

                        {hasEditBaseProductPrivilege && readAndWrite && <Col md={10} className="text-end">
                            <Button
                                onClick={() => handleReadAndWrite()}
                                className="btn btn-primary add-row mt-md-0 mx-2"
                            >
                                <FontAwesomeIcon icon={faPenToSquare} />&nbsp;
                                Edit
                            </Button>

                        </Col>}
                        {hasEditBaseProductPrivilege && !readAndWrite && <Col md={10} className="text-end">
                            <Button
                                disabled={!readAndWrite}
                                onClick={() => handleReadAndWrite()}
                                variant="danger"                >
                                <FontAwesomeIcon icon={faPenToSquare} />&nbsp;
                                Edit
                            </Button>

                        </Col>}

                    </Row>
                    <Row>
                        <Form noValidate className="needs-validation user-add" onSubmit={updateBaseProduct}>
                            <Form.Group className="mb-3" controlId="productNameCtrlId">
                                <Row>
                                    <Col md={2}>
                                        <Form.Label>Name<span>*</span></Form.Label>

                                    </Col>
                                    <Col md={8}>
                                        <Form.Control
                                            disabled={readAndWrite}
                                            className="w-75"
                                            value={name}
                                            type="text"
                                            onChange={e => { setName(e.target.value) }}
                                            required
                                        />
                                    </Col>

                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="categorySort">
                                <Row>
                                    <Col md={2}>
                                        <Form.Label>Code<span>*</span></Form.Label>

                                    </Col>
                                    <Col md={8}>
                                        <Form.Control
                                            disabled={readAndWrite}
                                            className="w-75"
                                            value={code}
                                            type="text"
                                            onChange={e => { setCode(e.target.value) }}
                                            required
                                        />
                                    </Col>

                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="productNameCtrlId">
                                <Row>
                                    <Col md={2}>

                                        <Form.Label>Variant</Form.Label>
                                    </Col>
                                    <Col md={4}>
                                        <Button disabled={readAndWrite} type="button" className="my-2" onClick={handleShow}>
                                            <FontAwesomeIcon icon={faCirclePlus} />  &nbsp;Add New</Button>

                                    </Col>
                                </Row>
                            </Form.Group>
                            <Card.Body className="card-body p-4">
                                <Col className="table-responsive table-desi">
                                    <Table striped bordered hover variant="white">
                                        <thead >
                                            <tr>
                                                <th>Variant Name</th>
                                                <th>Sort Order</th>
                                                <th>Variant Options</th>
                                                <th>Action</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {selectedVariants.map((data: any, idx: any) => (
                                                <tr >
                                                    <td data-fiels="name">{data.variantName}</td>
                                                    <td data-fiels="name">{data.sortOrder}</td>
                                                    <td data-fiels="name">{data.optionStr}</td>
                                                    <td >
                                                        <a
                                                            href="javascript:void(0)"
                                                            onClick={() => editBaseProductVariant(data)}
                                                            className={`cursor-pointer ${readAndWrite ? 'unselectable' : ''}`}
                                                            style={{ pointerEvents: readAndWrite ? 'none' : 'auto' }}
                                                        >
                                                            <FontAwesomeIcon icon={faPenToSquare} title="edit base product" className="me-2 font-success" />
                                                        </a>

                                                        <a
                                                            href="javascript:void(0)"
                                                            onClick={() => removeVariant(data)}
                                                            className={`cursor-pointer ${readAndWrite ? 'unselectable' : ''}`}
                                                            style={{ pointerEvents: readAndWrite ? 'none' : 'auto' }}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} title="edit base product" className="me-2 delete-btn" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Card.Body>
                            <Form.Group>
                                <Row >
                                    <Col xs={11} className="d-flex justify-content-end">
                                        <Button type="button" variant="danger" className="secondary mx-2" onClick={goBack} >
                                            Cancel
                                        </Button>
                                        <Button disabled={readAndWrite} type="submit" variant="primary" >Update</Button>

                                    </Col>
                                </Row>

                            </Form.Group>
                        </Form>
                    </Row>
                </Card>
            </Container>
        </Col>

        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>

                <Form.Group className="mb-3" controlId="productNameCtrlId">
                    <Row>
                        <Col md={6}>
                            <Row>
                                <Col>
                                    <Form.Label>Variant</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        as="select"
                                        className="mb-3"
                                        value={selectedVariant && selectedVariant.id}
                                        disabled={selectedVariant && selectedVariant.disabled}
                                        onChange={(e) => onVariantSelectedHandler(e)}>
                                        <option value="">Select Variant</option>
                                        {variantData && variantData.map((data: any, idx: any) => (
                                            <option key={idx} value={data.id}>{data.variantName}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Sort Order<span>*</span></Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        className=""
                                        type="number"
                                        placeholder="Sort Order"
                                        min={1}
                                        disabled={!selectedVariant || selectedVariant.id == ''}
                                        onChange={handleSortOrderChange}
                                        value={sortOrder}
                                        required
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col md={6}>
                            {variantOptions && variantOptions.map((option, index) => (
                                <Row className="mt-2">
                                    <Col>
                                        <div key={index}>
                                            <input
                                                type="checkbox"
                                                id={option.id}
                                                checked={option.checked}
                                                onChange={(e) => onVariantOptionSelected(e, option)}
                                            />
                                            <label className="mx-3" htmlFor={option.id}>{option.option}</label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            className=""
                                            type="number"
                                            placeholder="Sort Order"
                                            min={1}
                                            size="sm"
                                            disabled={option.disableSortOrder}
                                            onChange={(e) => onVariantOptionSortOrderChange(index, e, option)}
                                            value={option.sortOrder}
                                            required
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <Button type="button" className="my-2" onClick={handleAddClick}>Add </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Modal.Body>
        </Modal>

    </>
}

export default EditBaseProduct