import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AttributeApiService from "../../../data/services/attribute-api-service/attribute-api-service";
import variantTypeApiService from "../../../data/services/variant-type/variant-type-api-service";
import { AttributePayload } from "../../../data/services/attribute-api-service/attribute-payload";
import FormHeader from "../../../components/form-header/form-header";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import axios, { AxiosError } from 'axios';
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";

const attributeType = [
    {
        name: 'PRODUCT_SPECIFICATION',
        type: 'product_specification'
    },
]
const EditAttribute = () => {

    const attributeApiService: AttributeApiService = new AttributeApiService();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('EDIT ATTRIBUTE'));
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [type, setType] = useState('')
    const [attribute, setAttribute] = useState([])
    const { state } = useLocation();
    const id = state.id

    let navigate: any = useNavigate()
    const getAttributeById = async () => {
        try {
            let data = await attributeApiService.getAttributeById(id);
            attributeData(data);

            console.log("getAttributeById", data)
        } catch (error) {
            console.log("getAttributeById Error");
            console.log(error);
        }
    }

    const handleChange = (e: any) => {
        const selectedValue = e.target.value;
        setType(selectedValue)
        // setAttribute(id);
    };

    const goBack = () => {
        navigate('/main-layout/attribute-datatable');
    }

    const updateAttribute = async () => {
        try {
            let payload: AttributePayload = {
                name: name,
                code: code,
                type: type
            }
            await attributeApiService.editAttribute(id, payload);
            toast.success('Attribute Updated Successfully', { containerId: 'TR' });
            navigate('/main-layout/attribute-datatable')
        } catch (error: any) {
            console.log("Attribute Error");
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };
    
    const attributeData = (data: any) => {
        setName(data.name);
        setCode(data.code);
        setType(data.type)
    }

    const validateAttributeType = async (event:any) => {
        event.preventDefault();

        if (!name) {
            toast.error("Please Enter Name", { containerId: 'TR' });
            return;
        }
        if (!code) {
            toast.error("Please Enter Code", { containerId: 'TR' });
            return;
        }
        if (!type) {
            toast.error("Please Enter Type", { containerId: 'TR' });
            return;
        }
        await updateAttribute();
    }

    useEffect(() => {
        getAttributeById();
    }, []);
    return <>
        {/* <!-- Container-fluid starts--> */}
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={validateAttributeType}>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Name</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control

                                                    type="text" value={name}
                                                    autoComplete="off"
                                                    onChange={e => { setName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Code</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" value={code}
                                                    autoComplete="off"
                                                    onChange={e => { setCode(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>

                                                <Form.Label>Type</Form.Label>
                                            </Col>
                                            <Col md={6}>


                                                <select className="form-control" value={type} onChange={handleChange} >
                                                    <option value="">Select Type</option>
                                                    {attributeType.map((data: any, idx: any) => (
                                                        <option key={idx} value={data.type}>{data.name}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group>
                                    <Row >
                                        <Col xs={8} className="d-flex justify-content-end">
                                        <Button type="button" variant="danger" className="secondary mx-2" onClick={goBack} >
                                                Cancel
                                            </Button> 
                                            <Button type="submit" variant="primary" >&nbsp;Update</Button>
                                                
                                       </Col>
                                    </Row>

                                </Form.Group>
                           

                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
        </>
}
        export default EditAttribute