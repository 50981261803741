import axios, { AxiosError } from "axios";
import HttpClientWrapper from "../../api/http-client-wrapper";
import StorageService from "../../storage/storage-service";
import { AuthPayload } from "./auth-payload";
import { toast } from "react-toastify";

class AuthApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    fetchByUsername = async (username: any) => {
        console.log("START fetchByEmail = {} " + username);
        try {
            let data: any = await this.httpClientWrapper.get('/v1/auth/accounts/' + username + '/username');
            return data;
        } catch (error) {
            throw error;
        }
    }

    doLogin = async (payload: AuthPayload) => {
        console.log("AuthApiService doLogin() start = " + JSON.stringify(payload));
        try {
            if (StorageService.getToken() || StorageService.getToken != null) {
                window.localStorage.clear();
            }
            let data: any = await this.httpClientWrapper.post('/v1/auth/login', payload);
            if (data && data.data.access_token) {
                StorageService.setToken(data.data.access_token);
            }
            if (data) {
                StorageService.saveUserData(data.data);
            }
            console.log("acces token test");
            console.log("AuthApiService doLogin() response data " + JSON.stringify(data));
            console.log("AuthApiService doLogin() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }
}
export default AuthApiService;