import { toast } from "react-toastify";
import ApiConfig from "./api-config";
import axios, { AxiosError, AxiosInstance } from "axios";
import StorageService from "../storage/storage-service";

class HttpClientWrapper {

    private axiosClient: AxiosInstance;

    constructor() {
        this.axiosClient = new ApiConfig().getAxiosInstance();
    }
    private getApiError = (error: any) => {
        if (axios.isAxiosError(error)) {
            if (error.response && error.response.data && error.response.data.msg) {
                return error.response.data.msg;
            } else if (error.response && error.response.data && error.response.data.message) {
                return error.response.data.message;
            } else {
                return 'Unknown server error';
            }
        }
        return 'No response from server';
    }

    public post = async (path:string, payload: any) => {
        console.log("HttpClientWrapper post() start path = '"+path+"', payload = "+JSON.stringify(payload));
        try {
            console.log( this.axiosClient.defaults.baseURL);
            let response:any = await this.axiosClient.post(path, payload, this.getJsonHeaderConfig());
            console.log("HttpClientWrapper post() response data "+JSON.stringify(response.data.data));
            console.log("HttpClientWrapper post() end");
            return response;
        } catch(err: any) {
            console.log("HttpClientWrapper post() error=== "+JSON.stringify(err));
            // throw err;
            const errorMessage:string = this.getApiError(err);
            throw new Error(errorMessage);
        }
    }
    
    
    public download = async (path:string) => {
        console.log("HttpClientWrapper get() start path = "+path);
        try {
            let config:any = this.getJsonHeaderConfig();
            config['responseType'] = 'blob';
            let response:any = await this.axiosClient.get(path, config);
            console.log("HttpClientWrapper get() end path = "+path);
            return response.data;
        } catch(err: any) {
            console.log("HttpClientWrapper get() error=== "+JSON.stringify(err));
            const errorMessage:string = this.getApiError(err);
            throw new Error(errorMessage);
        }
    }

    public get = async (path:string) => {
        console.log("HttpClientWrapper get() start path = "+path);
        try {
            let response:any = await this.axiosClient.get(path, this.getJsonHeaderConfig());
            console.log("HttpClientWrapper get() response data "+JSON.stringify(response.data.data));
            console.log("HttpClientWrapper get() end path = "+path);
            return response.data;
        } catch(err: any) {
            console.log("HttpClientWrapper get() error=== "+JSON.stringify(err));
            const errorMessage:string = this.getApiError(err);
            throw new Error(errorMessage);
        }
    }

    public put = async (path:string, payload?:any) => {
        console.log("HttpClientWrapper put() start path = "+path);
        try {
            let response:any = await this.axiosClient.put(path, payload, this.getJsonHeaderConfig());
            console.log("HttpClientWrapper put() response data "+JSON.stringify(response.data));
            console.log("HttpClientWrapper put() end path = "+path);
            return response.data;
        } catch(err: any) {
            console.log("HttpClientWrapper put() error=== "+JSON.stringify(err));
            const errorMessage:string = this.getApiError(err);
            throw new Error(errorMessage);
        }
    }

    private handleApiError = (error: any) => {
        if(error instanceof AxiosError) {
            if (error.response) {
                const errorMessage = (error.response.data as { message: string }).message;
                toast.error(errorMessage, { containerId: 'TR' });
            } else {
                toast.error('No response from server', { containerId: 'TR' });
            }
        }
    };

    public delete = async (path:string) => {
        console.log("HttpClientWrapper delete() start path = "+path);
        try {
            let response:any = await this.axiosClient.delete(path, this.getJsonHeaderConfig());
            console.log("HttpClientWrapper delete() response data "+JSON.stringify(response));
            console.log("HttpClientWrapper delete() end path = "+path);
            return response.data.data;
        } catch(err: any) {
            console.log("HttpClientWrapper delete() error=== "+JSON.stringify(err));
            const errorMessage:string = this.getApiError(err);
            throw new Error(errorMessage);
        }
    }

    public postFormData = async (path:string, formData: FormData) => {
        console.log("HttpClientWrapper post() start path = '"+path+"'");
        try {
            let response:any = await this.axiosClient.post(path, formData, this.getFormDataHeaderConfig());
            console.log("HttpClientWrapper post() end path = '"+path+"'");
            return response.data;
        } catch(err: any) {
            console.log("HttpClientWrapper post() error=== "+JSON.stringify(err));
            //const errorMessage:string = this.getApiError(err);
            throw err;
        }
    }

    public putFormData = async (path:string, formData: FormData) => {
        console.log("HttpClientWrapper post() start path = '"+path+"'");
        try {
            let response:any = await this.axiosClient.put(path, formData, this.getFormDataHeaderConfig());
            console.log("HttpClientWrapper post() end path = '"+path+"'");
            return response.data;
        } catch(err: any) {
            console.log("HttpClientWrapper post() error=== "+JSON.stringify(err));
            const errorMessage:string = this.getApiError(err);
            throw new Error(errorMessage);
        }
    }

    getFormDataHeaderConfig = () => {
        return this.getHeaderConfig('multipart/form-data');
    }

    getHeaderConfig = (contentType: string):any => {
        let headers:any = {};
        headers['Content-Type'] = contentType;
        let token = StorageService.getToken();
        if(token) {
            headers['Authorization'] = 'Bearer '+token;
        }
        return { headers: headers}
    }

    getJsonHeaderConfig = () => {
        return this.getHeaderConfig('application/json');
    }
}
export default HttpClientWrapper;