import { Row, Col } from "react-bootstrap";
import Menu from "../layouts/header/components/menu";
import Header from "../layouts/header/header";
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoginState from "../data/state/login-state";
import { FeaturePrivilege } from "../context/feature-privilege";
import { PersonData } from "../context/person-data";
import { UserContext, UserContextData } from "../context/user-context";

const MainLayout = () => {
    
    const [showHeader, setShowHeader] = useState(true);
    const [colSize, setColSize] = useState(10);
    const loginReducer: any = useSelector((state: LoginState) => state);
    const [userContext, setUserContext] = useState<UserContextData>(new UserContextData());
    const [personData, setPersonData] = useState<PersonData>(new PersonData());


    const toggleHeader = () => {
        setShowHeader(!showHeader);
        setColSize(showHeader ? 12 : 10);
    }

    useEffect(() => {
        setUserContext(loginReducer.personData);
        setPersonData(loginReducer.personData);
    }, [userContext, personData]);

    return <>
        <Row className="page-wrapper">
            {showHeader &&
                <Col xs={2}>
                    <Col >
                        <Col className="page-body-wrapper">
                            <Menu />
                        </Col>
                    </Col>
                </Col>}
            <Col xs={colSize}>
                <Header toggleHeader={toggleHeader} personData={personData}/>
                <Col className="page-body">
                    <UserContext.Provider value={userContext}>
                        <Outlet />
                    </UserContext.Provider>
                </Col>
            </Col>
        </Row>
    </>
}
export default MainLayout;