import { useEffect, useState } from "react";
import EmailConfigApiService from "../../data/services/email-config-api-service/email-config-api-service";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import FormHeader from "../../components/form-header/form-header";
import React from "react";
import * as EmailValidator from 'email-validator';
import { faXmark, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../data/state/form-header-action";


const EditEmailConfig = () => {
    const emailConfigApiService: EmailConfigApiService = new EmailConfigApiService();
    const [location, setLocation] = useState('');
    const [locationData, setLocationData] = useState<any>([]);
    const [toMailAndccDetails, setToMailAndccDetails] = useState<any[]>([]);
    const { state } = useLocation();
    const id = state.id
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('EDIT EMAIL CONFIGURATION'));

    const handleToMailChangeChange = (index: any, value: any) => {
        const newToMailAndccDetails = [...toMailAndccDetails];
        newToMailAndccDetails[index] = { ...newToMailAndccDetails[index], toEmail: value };
        setToMailAndccDetails(newToMailAndccDetails);
    };

    const handleCCChange = (index: any, value: any) => {
        const newToMailAndccDetails = [...toMailAndccDetails];
        newToMailAndccDetails[index] = { ...newToMailAndccDetails[index], cc: value };
        setToMailAndccDetails(newToMailAndccDetails);
    };

    const addTOMailAndCC = () => {
        setToMailAndccDetails([...toMailAndccDetails, { toEmail: '', cc: '' }]);
    };

    const removeEmailConfigField = (index: any) => {
        const newMailAndCC = [...toMailAndccDetails];
        newMailAndCC.splice(index, 1);
        setToMailAndccDetails(newMailAndCC);
    };

    const getAllLocations = async () => {
        try {
            const data: any = await emailConfigApiService.getAllLocations();
            setLocationData(data);
        } catch (error) {
            console.log("getCategories Error");
            console.log(error);
        }
    }

    const getEmailConfigById = async () => {
        try {
            let data = await emailConfigApiService.getAllEmailConfigById(id);
            setData(data);
            console.log("getEmailConfigById", data)
        } catch (error) {
            console.log("getEmailConfigById Error");
            console.log(error);
        }
    }

    const setData = (data: any) => {
        setLocation(data.location.id);
        updateUI(data.emailConfigMapper);

    }
    const updateUI = (data: any) => {
        if (data.length == 0) {
            return;
        }
        let toMailDataArray: any[] = [];
        for (let i = 0; i < data.length; i++) {
            let toMailData: any = {};
            toMailData.toEmail = data[i].toEmail;
            toMailData.cc = data[i].cc;
            toMailDataArray.push(toMailData);
        }
        setToMailAndccDetails([...toMailDataArray]);
    }

    const updateEmailConfig = async (event: any) => {
        try {
            event.preventDefault();
            if (!location) {
                toast.error("Please Select Location", { containerId: 'TR' });
                return;
            }
            if (!toMailAndccDetails || toMailAndccDetails.length == 0) {
                toast.error("Please enter Email And CC ", { containerId: 'TR' });
                return;
            } else {
                for (let i = 0; i < toMailAndccDetails.length; i++) {
                    if (!toMailAndccDetails[i].toEmail) {
                        throw new Error("Empty to email not allowed");
                    }
                    if (!EmailValidator.validate(toMailAndccDetails[i].toEmail)) {
                        throw new Error("to email of " + toMailAndccDetails[i].toEmail + " isnot in email format");
                    }
                    if (toMailAndccDetails[i].cc) {
                        if (!EmailValidator.validate(toMailAndccDetails[i].cc)) {
                            throw new Error("cc of " + toMailAndccDetails[i].cc + " is not in email format");
                        }
                    }

                }
            }

            let payload: any = {
                locationId: location,
                emailConfigReq: toMailAndccDetails,
            }
            await emailConfigApiService.updateEmailConfig(id, payload);
            toast.success('EmailConfig Updated Successfully', { containerId: 'TR' });
            navigate('/main-layout/email-config-datatable');
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("EmailConfig Update Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const goBack = () => {
        navigate('/main-layout/email-config-datatable');
    }

    useEffect(() => {
        getAllLocations();
        getEmailConfigById();
    }, []);

    return <>
        {/* <FormHeader title="Edit Email Config" /> */}
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={updateEmailConfig}>
                                    <Row>
                                        <Form.Group className="mb-3" controlId="productNameCtrlId">
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Label>Location<span>*</span></Form.Label>

                                                </Col>
                                                <Col md={5}>
                                                    <Form.Select aria-label="Select Location" value={location} onChange={event => setLocation(event.target.value)}>
                                                        <option value="">Select</option>
                                                        {locationData.map((item: any, idx: any) => (
                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                        ))}

                                                    </Form.Select>
                                                </Col>

                                            </Row>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="productNameCtrlId">
                                            {/* <Col md={10} className="text-end">
                                                <Button onClick={addTOMailAndCC}>Add Email</Button>
                                            </Col> */}
                                            <Row>
                                                {toMailAndccDetails && toMailAndccDetails.map((data, idx) => (
                                                    <React.Fragment key={idx}>
                                                        <Row>
                                                            <Col md={2}>
                                                                <Form.Label>To Email<span>*</span></Form.Label>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Control
                                                                    type="toEmail"
                                                                    autoComplete="off"
                                                                    value={data.toEmail}
                                                                    onChange={(e) => handleToMailChangeChange(idx, e.target.value)}
                                                                    required
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={2}>
                                                                <Form.Label>CC</Form.Label>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Control
                                                                    type="cc"
                                                                    autoComplete="off"
                                                                    value={data.cc}
                                                                    onChange={(e) => handleCCChange(idx, e.target.value)}
                                                                    required
                                                                />
                                                                {/* <Button type="button" variant="danger" className="mx-2" onClick={() => removeEmailConfigField(idx)}>
                                                                    Remove
                                                                </Button> */}
                                                            </Col>
                                                            <Col md={2} className="mt-2">
                                                                <FontAwesomeIcon icon={faXmark} size="2xl" textAnchor="Remove" style={{ cursor: 'pointer' }} onClick={() => removeEmailConfigField(idx)} />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                                <Row>
                                                    <Col md={2}>

                                                    </Col>
                                                    <Col md={4} className=" mt-3">
                                                        <Button onClick={addTOMailAndCC}><FontAwesomeIcon icon={faCirclePlus} />
                                                            &nbsp;Add New</Button>

                                                    </Col>
                                                </Row>

                                            </Row>
                                        </Form.Group>
                                    </Row>

                                    <Form.Group>
                                        <Row >
                                            <Col xs={11} className="d-flex justify-content-end">
                                                <Button type="button" variant="danger" className="secondary mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" >Update</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>
                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>

    </>
}

export default EditEmailConfig