import { useEffect, useState } from "react";
import { faPenToSquare, faTrash, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, CardBody, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { AxiosError } from 'axios';
import FormHeader from "../../components/form-header/form-header";
import BaseProductApiService from "../../data/services/base-product-api-service/base-product-api-service";
import VariantApiService from "../../data/services/variant/variant-api-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../data/state/form-header-action";


let immutableVariants: any[] = [];

const AddBaseProduct = () => {

    const baseProductApiService: BaseProductApiService = new BaseProductApiService;
    const variantApiService: VariantApiService = new VariantApiService;
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [variantData, setVariantData] = useState<any[]>([]);
    const [selectedVariants, setSelectedVariants] = useState<any[]>([]);
    let navigate: any = useNavigate();
    const [show, setShow] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState<any>();
    const [variantOptions, setVariantOptions] = useState<any[]>([]);
    const [sortOrder, setSortOrder] = useState('');
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ADD BASE PRODUCT'));


    const saveBaseProduct = async (event: any) => {
        try {
            event.preventDefault();
            let payload: any = {
                name: name,
                code: code,
                variants: constructVariantPayload(selectedVariants)
            }
            await baseProductApiService.createBaseProduct(payload);
            toast.success('BaseProduct Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/base-product-datatable')
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("BaseProduct Create Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }

    }

    const constructVariantPayload = (selectedVariants: any[]) => {
        const variants: any[] = [];
        for (let i = 0; i < selectedVariants.length; i++) {
            const variant: any = {};
            variant.id = selectedVariants[i].id;
            variant.sortOrder = parseInt(selectedVariants[i].sortOrder);
            variant.options = constructVariantOptionPayload(selectedVariants[i].options);
            variants.push(variant);
        }
        return variants;
    }

    const constructVariantOptionPayload = (selectedVariantOptions: any[]) => {
        const options: any[] = [];
        for (let i = 0; i < selectedVariantOptions.length; i++) {
            const option: any = {};
            option.id = selectedVariantOptions[i].id;
            option.sortOrder = selectedVariantOptions[i].sortOrder;
            options.push(option);
        }
        return options;
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const goBack = () => {
        navigate('/main-layout/base-product-datatable');
    }

    const getVariantData = async () => {
        try {
            let data = await variantApiService.getAllVariant();
            console.log('getVariantData data', data);
            for (let i = 0; i < data.length; i++) {
                immutableVariants.push(data[i]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddClick = () => {
        try {
            if (!selectedVariant) {
                throw new Error("Please select variant");
            }
            if (!sortOrder) {
                throw new Error("Please enter variant sort order");
            }
            const variant: any = {};
            variant.id = selectedVariant.id;
            variant.variantName = selectedVariant.variantName;
            variant.sortOrder = sortOrder;
            const options: any[] = [];
            const optionsStr: any[] = [];
            for (let i = 0; i < variantOptions.length; i++) {
                if (variantOptions[i].checked) {
                    options.push(variantOptions[i]);
                    optionsStr.push(variantOptions[i].option);
                }
            }
            if (options.length == 0) {
                throw new Error("Please select variant option");
            } else {
                for (let i = 0; i < options.length; i++) {
                    if (!options[i].sortOrder) {
                        throw new Error("Please enter variant option sort order");
                    }
                }
            }
            variant.options = options;
            variant.optionStr = optionsStr.join(',');
            pushToDataTable(variant);
        } catch(error:any) {
            toast.error(error.message, { containerId: 'TR' });
        }
    };


    const pushToDataTable = (variant: any) => {
        let idx = -1;
        for (let i = 0; i < selectedVariants.length; i++) {
            if (selectedVariants[i].id == variant.id) {
                idx = i;
                break;
            }
        }
        if (idx == -1) {
            selectedVariants.push(variant);
        } else {
            selectedVariants.splice(idx, 1, variant);
        }
        setSelectedVariants([...selectedVariants]);
        setShow(false);
    }

    const onVariantSelectedHandler = (e: any) => {
        const selectedVariantId = e.target.value;
        setSortOrder('');
        if (!selectedVariantId) {
            setSelectedVariant('');
            setVariantOptions([]);
            return;
        }
        const variant: any = variantData.find((v) => v.id === selectedVariantId);
        setSelectedVariant(variant);
        for (let i = 0; i < variant.options.length; i++) {
            variant.options[i].sortOrder = '';
            variant.options[i].checked = highlightOnOptionAlreadySelected(selectedVariantId, variant.options[i].id);
            variant.options[i].disableSortOrder = !variant.options[i].checked;
        }
        setVariantOptions(variant.options);
    };

    const highlightOnOptionAlreadySelected = (variantId: string, optionId: string) => {
        if (selectedVariants.length > 0) {
            let tmpOptions: any[] = [];
            for (let i = 0; i < selectedVariants.length; i++) {
                if (selectedVariants[i].id == variantId) {
                    tmpOptions = selectedVariants[i].options;
                    break;
                }
            }
            if (tmpOptions.length > 0) {
                let highlightOption: boolean = false;
                for (let i = 0; i < tmpOptions.length; i++) {
                    if (tmpOptions[i].id == optionId) {
                        highlightOption = true;
                        break;
                    }
                }
                return highlightOption;
            }
        }
        return false;
    }

    const onVariantOptionSelected = (e: any, option: any) => {
        for (let i = 0; i < variantOptions.length; i++) {
            if (variantOptions[i].id == option.id) {
                variantOptions[i].checked = e.target.checked;
                variantOptions[i].disableSortOrder = !e.target.checked;
                if(!e.target.checked && variantOptions[i].sortOrder != '') {
                    variantOptions[i].sortOrder = '';
                }
            }
        }
        setVariantOptions([...variantOptions]);
    };

    const removeVariant = (item: any, idx: number) => {
        selectedVariants.splice(idx, 1);
        setSelectedVariants([...selectedVariants]);
    }

    const handleShow = () => {
        setSelectedVariant('');
        setVariantOptions([]);
        populateVariantData();
        setSortOrder("");
        setShow(true);
    }

    const onVariantOptionSortOrderChange = (index: number, event: any, option:any) => {
        variantOptions[index].sortOrder = event.target.value;
        setVariantOptions([...variantOptions]);
    };

    const populateVariantData = () => {
        const dialogVariants: any[] = [];
        for (let i = 0; i < immutableVariants.length; i++) {
            let isAlreadyAssigned: boolean = false;
            for (let j = 0; j < selectedVariants.length; j++) {
                if (immutableVariants[i].id == selectedVariants[j].id) {
                    isAlreadyAssigned = true;
                    break;
                }
            }
            if (!isAlreadyAssigned) {
                dialogVariants.push(immutableVariants[i]);
            }
        }
        setVariantData([...dialogVariants]);
    }

    const editBaseProductVariant = (item: any) => {
        item.disabled = true;
        const dialogVariants: any[] = [];
        dialogVariants.push(item);
        setVariantData([...dialogVariants]);
        setSelectedVariant(item);
        setSortOrder(item.sortOrder);
        const options: any[] = getEditOptions(item);
        setVariantOptions([...options]);
        setShow(true);
    }

    const getEditOptions = (item: any) => {
        const variantRow: any = immutableVariants.find((v) => v.id === item.id);
        for (let i = 0; i < variantRow.options.length; i++) {
            let isOptionSelected: boolean = false;
            for (let j = 0; j < item.options.length; j++) {
                if (variantRow.options[i].id == item.options[j].id) {
                    isOptionSelected = true;
                    variantRow.options[i].sortOrder = item.options[j].sortOrder;
                    break;
                }
            }
            variantRow.options[i].checked = isOptionSelected;
            variantRow.options[i].disableSortOrder = !isOptionSelected;
        }
        return variantRow.options;
    }

    const handleSortOrderChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*$/.test(inputValue) || inputValue === '') {
            setSortOrder(inputValue);
        }
    }

    const handleClose = () => {
        setShow(false);
        setSelectedVariant([]);
    };

    useEffect(() => {
        getVariantData();
        return () => {
            immutableVariants = [];
        };
    }, []);

    return <>
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={saveBaseProduct}>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Name<span>*</span></Form.Label>

                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete="off"
                                                    onChange={e => { setName(e.target.value) }}
                                                    required
                                                />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Code<span>*</span></Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete="off"
                                                    onChange={e => { setCode(e.target.value) }}
                                                    required
                                                />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                        <Col md={2}>
                                               
                                            </Col>
                                            <Col md={4}>
                                                <Button type="button" className="my-2" onClick={handleShow}>
                                                <FontAwesomeIcon icon={faCirclePlus} />
                                &nbsp; Add New</Button>

                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Card.Body className="p-0">
                                        <Col>
                                            <Table className=' all-package table-category' variant="white">
                                                <thead >
                                                    <tr>
                                                        <th>Variant Name</th>
                                                        <th>Sort Order</th>
                                                        <th>Variant Options</th>
                                                        <th>Action</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {selectedVariants.map((data: any, idx: any) => (
                                                        <tr>
                                                            <td>{data.variantName}</td>
                                                            <td>{data.sortOrder}</td>
                                                            <td>{data.optionStr}</td>
                                                            <td>
                                                                <a href="javascript:void(0)" onClick={() => editBaseProductVariant(data)} className="cursor-pointer">
                                                                    <FontAwesomeIcon icon={faPenToSquare} title="edit base product" className='me-2 font-success' />
                                                                </a>
                                                                <a href="javascript:void(0)" onClick={() => removeVariant(data, idx)}>
                                                                    <FontAwesomeIcon icon={faTrash} title="delete Variant Type" className='me-2 font-success' />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Card.Body>
                                    <Form.Group>
                                        <Row >
                                            <Col xs={12} className="d-flex justify-content-end">
                                                <Button type="button" variant="danger" className="mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" >Save &nbsp;</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>
                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="productNameCtrlId">
                    <Row>
                        <Col md={6}>
                            <Row>
                            <Col>
                                <Form.Label>Variant</Form.Label>
                                </Col>
                                <Col>
                                <Form.Control
                                    as="select"
                                    className="mb-3"
                                    value={selectedVariant && selectedVariant.id}
                                    disabled={selectedVariant && selectedVariant.disabled}
                                    onChange={(e) => onVariantSelectedHandler(e)}>
                                    <option value="">Select Variant</option>
                                    {variantData && variantData.map((data: any, idx: any) => (
                                        <option key={idx} value={data.id}>{data.variantName}</option>
                                    ))}
                                </Form.Control>
                                </Col>
                            </Row>
                            <Row>
                            <Col>
                            <Form.Label>Sort Order<span>*</span></Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                className=""
                                type="number"
                                placeholder="Variant Sort Order"
                                autoComplete="off"
                                min={1}
                                disabled={!selectedVariant || selectedVariant.id == ''}
                                onChange={handleSortOrderChange}
                                value={sortOrder}
                                required
                            />
                        </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            {variantOptions && variantOptions.map((option, index) => (
                            <Row>
                                <Col className="mt-2">
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        id={option.id}
                                        checked={option.checked}
                                        onChange={(e) => onVariantOptionSelected(e, option)}
                                    />
                                    <label className="mx-3" htmlFor={option.id}>{option.option}</label>
                                    </div>
                                    </Col>
                                        <Col>
                                            <Form.Control
                                                className=""
                                                type="number"
                                                placeholder="Option Sort Order"
                                                min={1}
                                                size="sm"
                                                autoComplete="off"
                                                disabled={option.disableSortOrder}
                                                onChange={(e) => onVariantOptionSortOrderChange(index, e, option)}
                                                value={option.sortOrder}
                                                required
                                            />
                                        </Col>
                                 </Row>
                            ))}

                           
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={1}>

                            <Form.Label>Variant</Form.Label>
                        </Col>
                        <Col md={2}>
                            <Form.Control
                                as="select"
                                className="mb-3"
                                value={selectedVariant && selectedVariant.id}
                                disabled={selectedVariant && selectedVariant.disabled}
                                onChange={(e) => onVariantSelectedHandler(e)}>
                                <option value="">Select Variant</option>
                                {variantData && variantData.map((data: any, idx: any) => (
                                    <option key={idx} value={data.id}>{data.variantName}</option>
                                ))}
                            </Form.Control>
                        </Col>


                        <Col md={2}>
                            <Form.Label>Variant Options:</Form.Label>
                        </Col>

                        <Col md={2}>
                            {variantOptions && variantOptions.map((option, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        id={option.id}
                                        checked={option.checked}
                                        onChange={(e) => onVariantOptionSelected(e, option)}
                                    />
                                    <label className="mx-3" htmlFor={option.id}>{option.option}</label>
                                    <div>
                                        <Col md={1}>
                                            <Form.Label>Sort Order<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={1}>
                                            <Form.Control
                                                className=""
                                                type="number"
                                                min={1}
                                                onChange={(e) => handleInputChange(index, e)}
                                                value={sortOrder}
                                                required
                                            />
                                        </Col>
                                    </div>
                                </div>
                            ))}
                        </Col>
                        <Col md={2}>
                            <Form.Label>Sort Order<span>*</span></Form.Label>
                        </Col>
                        <Col md={4}>
                            <Form.Control
                                className=""
                                type="number"
                                placeholder="Sort Order"
                                min={1}
                                onChange={handleSortOrderChange}
                                value={sortOrder}
                                required
                            />
                        </Col>
                       
                    </Row> */}
                    <Row className="mt-3">
                        <Col md={3}>
                        </Col>
                            <Col md={2} >
                                <Button type="button" className="my-2" onClick={handleAddClick}>Add </Button>
                            </Col>
                        </Row>
                </Form.Group>

            </Modal.Body>
        </Modal>

    </>
}

export default AddBaseProduct