import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ProductApiService from "../../../data/services/product/product-api-service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";

const ProductGallery = (props: any) => {
    const productApiService: ProductApiService = new ProductApiService;
    const [defaultImage, setDefaultImage] = useState<any>();
    const [previewImage, setPreviewImage] = useState<any>();
    const [previewImages, setPreviewimages] = useState<any[]>([]);
    const [selectedGalleryImages, setSelectedGalleryImages] = useState<any[]>([]);
    let navigate: any = useNavigate();
    const [formData] = useState<any>(new FormData());



    const handleProductImage = (e: any) => {
        let files: any[] = e.target.files;
        if (files.length > 0) {
            let images: any[] = [];
            let galleryImages: any[] = [];
            for (let i = 0; i < files.length; i++) {
                let url: any = URL.createObjectURL(files[i]);
                let image: any = {};
                let galleryImage: any = {};
                image.imagePath = url;
                galleryImage = files[i];
                images.push(image);
                galleryImages.push(galleryImage);
            }
            setPreviewimages([...images]);
            setSelectedGalleryImages([...galleryImages]);
        }
    }

    const validateAndSaveProductGalleryFormData = async () => {
        try {
            if (!props.productId) {
                throw new Error(' product ID is undefined.');
            }
            if (formData.has('files')) {
                formData.delete('file');
            }
            [...selectedGalleryImages].forEach(image => {
                formData.append("files", image);
            });

            await productApiService.createProductGallery(props.productId, formData);
            toast.success('Product Gallery Saved Successfully', { containerId: 'TR' });
            handleClose();
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.error(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };


    const handleClose = () => {
        props.handleClose();
    };

    const clearPreviewImage = (index: number) => {
        const updatedItems = [...previewImages];
        updatedItems.splice(index, 1);
        setPreviewimages(updatedItems);

        const updatedImages = [...selectedGalleryImages];
        updatedImages.splice(index, 1);
        setSelectedGalleryImages(updatedImages);
    };

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Container fluid>
                            <Row className="justify-content-end">
                                <Col className="text-end">
                                    <Button type="button" variant="danger" className="my-4 mx-2" onClick={handleClose} >
                                        Cancel
                                    </Button>
                                    <Button type="button" className="btn btn-primary my-4"
                                        onClick={validateAndSaveProductGalleryFormData}
                                    >Save</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Form>
                                    <Form.Group className="mb-3" controlId="productMoqCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Upload Default Images</Form.Label>
                                            </Col>
                                            <Col md={8} className="col-xl-8 col-md-7">
                                                <input
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        handleProductImage(e);
                                                    }}
                                                    type="file"
                                                    multiple
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col className="custom-width mx-5 my-5">
                                                <Row xs={5} md={2} className="g-5">
                                                    {previewImages.map((image, index) => (
                                                        <Col key={index}>
                                                            <img
                                                                src={image.imagePath}
                                                                alt={`preview-${index}`}
                                                                width={800}
                                                                height={500}
                                                                className="img-thumbnail"
                                                            />
                                                            <Button
                                                                className="rounded-pill mt-2"
                                                                variant="danger"
                                                                aria-label="Close"
                                                                size="sm"
                                                                onClick={() => clearPreviewImage(index)}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </Col>
                                                    ))}

                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );

}
export default ProductGallery;