import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import VariantRuleApiService from "../../../data/services/variant-rule/variant-rule-api-service";
import VariantApiService from "../../../data/services/variant/variant-api-service";
import { toast } from "react-toastify";
import ValidationError from "../../../common/validation-error";
import VariantTypeApiService from "../../../data/services/variant-type/variant-type-api-service";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import BaseProductApiService from "../../../data/services/base-product-api-service/base-product-api-service";

const LinkVariant = (props: any) => {
    const baseProductApiService: BaseProductApiService = new BaseProductApiService();
    const [variantData, setVariantData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<[]>([]);
    const [selectedBaseProduct, setSelectedBaseProduct] = useState<any>();
    const [selectedProductType, setSelectedProductType] = useState('');
    const [variantName, setVariantName] = useState('');



    const handleOptionChange = (event: any, idx: any, options: any) => {
        const variantOptionId = event.target.value;
        for (let i = 0; i < options.length; i++) {
            if (options[i].option.id == variantOptionId.trim()) {
                options[i].selected = true;
            } else {
                options[i].selected = false;
            }
        }
        variantData[idx].options = options;
        setVariantData([...variantData]);
    };
    

    const constructSelectedVariants = () => {
        const variants: any[] = [];
        for (let i = 0; i < variantData.length; i++) {
            const variant: any = {};
            variant.variantId = variantData[i].variant.id;
            const optionId = constructVariantOptionPayload(variantData[i].options);
            if(!optionId) {
                throw new Error("please select "+variantData[i].variant.variantName+" option");
            }
            variant.optionId = optionId;
            variants.push(variant);
        }
        return variants;
    }

   
    const constructVariantOptionPayload = (variantOptions: any[]): string => {
        let optionId: any;
        for (let i = 0; i < variantOptions.length; i++) {
            if (variantOptions[i].selected) {
                optionId = variantOptions[i].option.id;
                break;
            }
        }
        return optionId;
    }

    const validateAndSaveLinkVariantFormData = () => {
        try {
            const productFormData = props.productFormData;
            if(productFormData.get('productType') == 'NORMAL') {
                return;
            }
            if (!selectedBaseProduct || !selectedBaseProduct[0].id) {
                throw new Error("Please select base product");
            }
            if (productFormData.has('baseProductId')) {
                productFormData.delete('baseProductId');
            }
            productFormData.append('baseProductId', selectedBaseProduct[0].id);
            if (productFormData.has('variants')) {
                productFormData.delete('variants');
            }
            productFormData.append('variants', JSON.stringify(constructSelectedVariants()));
        } catch (error: any) {
            throw error;
        }
    }

    const goBack = () => {
        props.onPreviousCallback();
    }

    const doSubmit = (event: any) => {
        try {
            event.preventDefault();
            validateAndSaveLinkVariantFormData();
            props.onNextCallback();
        } catch (error: any) {
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const handleSearch = async (query: string) => {
        setIsLoading(true);
        const paginationProduct: any = await baseProductApiService.getAllBaseProduct(query);
        setOptions(paginationProduct.items);
        setIsLoading(false);
    };

    const onBaseProductSelected = async (selectedItem: any) => {
        setSelectedBaseProduct(selectedItem);
        const baseProductDetails: any = await baseProductApiService.getBaseProductById(selectedItem[0].id);
        setVariantData(baseProductDetails.baseProductVariants);
    }

    useEffect(() => {
        for (const pair of props.productFormData.entries()) {
            console.log(pair[0], pair[1]);
        }
    }, [props]);

    return <>
        <Container>
            <Form className="needs-validation" onSubmit={doSubmit}>
                <Form.Group className="mt-3" controlId="productNameCtrlId">
                    <Row>
                        <Col md={2}>
                            <Form.Label>Base Product
                                {(selectedProductType === 'VARIANT_PRODUCT') && <span>*</span>}</Form.Label>
                        </Col>
                        <Col md={8}>
                            <AsyncTypeahead
                                filterBy={() => true}
                                id="product-search-box"
                                isLoading={isLoading}
                                labelKey="name"
                                minLength={1}
                                onChange={onBaseProductSelected}
                                onSearch={handleSearch}
                                options={options}
                                placeholder="Search by products"
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mt-5" controlId="productNameCtrlId">
                    {variantData.length > 0 && variantData.map((variantData: any, idx: any) => (
                        <Row>
                            <Col md={2}>
                                <Form.Label>Variant Name</Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Label>{variantData.variant.variantName}</Form.Label>
                            </Col>
                            <Col md={1}>
                                <Form.Label>Option<span>*</span></Form.Label>
                            </Col>
                            <Col md={3}>
                                <select className="form-control"
                                    onChange={(e) => { handleOptionChange(e, idx, variantData.options) }}>
                                    <option value="">Select option</option>
                                    {variantData.options && variantData.options.map((item: any, idx: any) => (
                                        <option key={idx} selected={item.selected} value={item.option.id}>{item.option.option}</option>
                                    ))}
                                </select>
                            </Col>
                        </Row>
                    ))}
                </Form.Group>
                <Form.Group className="mb-3" controlId="productMoqCtrlId">
                    <Row className="justify-content-end">
                        <Col className="text-end">
                            <Button type="button" variant="danger" className="my-4 mx-2"
                                onClick={goBack}
                            >back</Button>
                            <Button type="submit" className="btn btn-primary my-4 mx-2">Next</Button>
                            {/* <Col className="pull-right">
                                
                            </Col> */}
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Container>

    </>
}
export default LinkVariant;