import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import CategoriesApiService from "../../../data/services/category/category-api-service";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import FormHeader from "../../../components/form-header/form-header";

const AddSubCategory = () => {

    const categoriesApiService: CategoriesApiService = new CategoriesApiService;

    const [subCategories, setSupCategories] = useState('')

    let navigate: any = useNavigate()

    const { state } = useLocation();

    const id = state.id

    const validateSubCategories = async () => {
        if (!subCategories) {
            toast.error("Please Enter SubCategories", { containerId: 'TR' });
            return;
        }
        await addSubCategory();
    }

    const canecl = () => {
        navigate('/main-layout/categories-datatable')
    }

    const addSubCategory = async () => {
        try {
            let payload = {
                subCategories: subCategories
            }
            await categoriesApiService.createSubCategories(id, payload);
            toast.success('Dimension Updated Successfully', { containerId: 'TR' });
            navigate('/main-layout/categories-datatable')
        } catch (error: any) {
            console.log("Dimension Error")
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    return <>
        <FormHeader title="Add SubCategories" />
        {/* <!-- Container-fluid starts--> */}
        <Container fluid>
            <Row >
                <Col className="">

                    <Col className="tab-content" id="myTabContent">
                        <Col className="tab-pane fade active show" id="account" role="tabpanel"
                            aria-labelledby="account-tab">
                            <Form className="needs-validation user-add my-5">
                                <Form.Group>
                                    <label htmlFor="validationCustom0"
                                        className="col-xl-3 col-md-4">Sub Category<span>*</span></label>
                                    <Col className="col-xl-8 col-md-7">
                                        <Form.Control
                                            type="text" value={subCategories}
                                            onChange={e => { setSupCategories(e.target.value) }}
                                            required />
                                    </Col>
                                    <Button variant="danger" className="mx-4" onClick={canecl}>Cancel</Button>
                                    <Button type="submit" variant="primary" className="my-4" onClick={validateSubCategories}>Save</Button>

                                </Form.Group>
                            </Form>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </Container>
    </>
}
export default AddSubCategory