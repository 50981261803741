import axios, { AxiosInstance } from "axios";
class ApiConfig {

    // private baseURL = 'http://localhost:6565/';

    // ****production****
    // private baseURL = 'https://api.ecopackagingdirect.com.au/';

    // *****stage******
    // private baseURL = 'http://adminecodirect.onebitconsult.com/';
    private baseURL = 'https://biobox.au/';


    private apiBaseUrl: string;

    constructor() {
        this.apiBaseUrl = this.baseURL + 'api/';
    }
    private getApiBaseURL = () => {
        return this.apiBaseUrl;
    }

    public getAxiosInstance = () => {
        return axios.create({ baseURL: this.getApiBaseURL() });
    }

}
export default ApiConfig;
