import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LocationApiService from "../data/services/Location-api-service/location-api-service";
import { LocationPayload } from "../data/services/Location-api-service/location-payload";
import AccountApiService from "../data/services/account/account-api-service";
import { ChangePasswordPayload } from "../data/services/account/change-password-payload";
import axios, { AxiosError } from "axios";
import FormHeader from "../components/form-header/form-header";

const ChangePassword = () => {
    const accountApiService: AccountApiService = new AccountApiService();
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    let navigate: any = useNavigate();

    const validateAccount = async (event: any) => {
        event.preventDefault();
        if (!password) {
            toast.error("Please Enter  Password", { containerId: 'TR' });
            return;
        }
        if (!oldPassword) {
            toast.error("Please Enter confirm Password", { containerId: 'TR' });
            return;
        }
        if (password.trim() != oldPassword.trim()) {
            toast.error("New password must be different from the old password", { containerId: 'TR' });
            return;
        }
        await updateAccount();
    }

    const updateAccount = async () => {
        try {
            const payload: ChangePasswordPayload = new ChangePasswordPayload();
            payload.password = password.trim();
            await accountApiService.editAccount(payload);
            toast.success('Password Changed Successfully', { containerId: 'TR' });
            navigate('/')
        } catch (error: any) {
            console.log("Account Error");
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    useEffect(() => {
        
    }, []);

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const goBack = () => {
        navigate('/main-layout/categories-datatable');
    }


    return <>
     <FormHeader title="Change Password" />
        <Container fluid>
            <Row>
                <Col className="mt-5">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={validateAccount}>
                                    <Form.Group className="mb-3" controlId="oldPasswordCtrlId">
                                        <Row>
                                            <Col md={3}>
                                                <Form.Label>New Password</Form.Label>
                                            </Col>
                                            <Col md={5}>
                                                <Form.Control
                                                    type="password"
                                                    value={oldPassword}
                                                    onChange={(e) => {
                                                        setOldPassword(e.target.value);
                                                    }}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="passwordCtrlId">
                                        <Row>
                                            <Col md={3}>
                                                <Form.Label>Confirm Password</Form.Label>
                                            </Col>
                                            <Col md={5}>
                                                <Form.Control
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row >
                                            <Col xs={12} className="d-flex justify-content-end">
                                                <Button type="submit" variant="primary" >Update</Button>
                                                <Button type="button" variant="danger" className="secondary mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
}
export default ChangePassword;