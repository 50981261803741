import HttpClientWrapper from "../../api/http-client-wrapper";

class RolePermissionApiService {
    private httpClientWrapper: HttpClientWrapper;
    constructor(){
        this.httpClientWrapper = new HttpClientWrapper();
    }
    createRolePermission = async (id:any,payload:any) => {
        try {
            let data: any = await this.httpClientWrapper.post('v1/roles/' + id + '/rolePermission', payload);
            console.log("RolePermissionApiService createRolePermission() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    updateRolePermission = async (roleId:any,permissionId:any,payload:any) => {
        try {
            let data: any = await this.httpClientWrapper.put('v1/roles/' + roleId + '/rolePermission/' + permissionId, payload);
            console.log("RolePermissionApiService updateRolePermission() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    createRolePrivilege = async (id:any,payload:any) => {
        try {
            let data: any = await this.httpClientWrapper.post('v1/roles/' + id + '/features', payload);
            console.log("RolePermissionApiService createRolePrivilege() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAllRolePermission = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('v1/role-permission');
            console.log("RolePermissionApiService getAllRolePermission() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


    getRolePermissionById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/RolePermissions/' + id);
            console.log("RolePermissionApiService getRolePermissionById() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getFeatureActionByRoleAndFeatureId = async (roleId:string, featureId: string) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/roles/' + roleId +'/rolePermission/' + featureId);
            console.log("RolePermissionApiService getFeatureActionByRoleAndFeatureId() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


}
export default RolePermissionApiService