import HttpClientWrapper from "../../api/http-client-wrapper";

class AttributeApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    createAttribute = async (payload:any) => {
        try {
            let data: any = await this.httpClientWrapper.post('v1/attributes', payload);
            console.log("AttributeApiService createAttribute() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAllAttribute = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('v1/attributes/productSpecification');
            console.log("AttributeApiService getAllAttribute() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


    getAttributeById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/attributes/' + id);
            console.log("AttributeApiService getAttributeById() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    editAttribute = async (id: any,payload:any ) => {
        try {
            let data: any = await this.httpClientWrapper.put('/v1/attributes/' + id, payload);
            console.log("AttributeApiService editAttribute() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


    deleteAttribute = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/attributes/' + id);
            console.log("AttributeApiService deleteAttribute() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }
}
export default AttributeApiService