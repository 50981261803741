import HttpClientWrapper from "../../api/http-client-wrapper";

class VariantRuleApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllVariantRules = async() => {
        try{
            let data:any= await this.httpClientWrapper.get('v1/variantRules/fetchAll' );
            console.log("VariantRuleApiService getAllVariantRule() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getAllVariantTypeByRuleId = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/variantRules/' + id + '/variantTypes' );
            console.log("VariantRuleApiService getAllVariantRule() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getVariantRuleById = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.get('v1/variantRules/'+id );
            console.log("VariantRuleApiService getVariantRuleById() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    createVariantRule = async(payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.post('v1/variantRules',payload );
            console.log("VariantRuleApiService createVariantRule() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    editVariantRule = async(id:any, payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.put('v1/variantRules/'+id, payload );
            console.log("VariantApiService editVariantRule() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    deleteVariantRule = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.delete('v1/variantRules/'+id );
            console.log("VariantRuleApiService deleteVariantRule() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }
}
export default VariantRuleApiService;