import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import BaseProductApiService from "../../../../data/services/base-product-api-service/base-product-api-service";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ProductApiService from "../../../../data/services/product/product-api-service";
import { toast } from "react-toastify";

let product: any = {};

const EditLinkVariant = (props: any) => {
    const baseProductApiService: BaseProductApiService = new BaseProductApiService();
    const productApiService: ProductApiService = new ProductApiService();
    const [variantData, setVariantData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<[]>([]);
    const [selectedBaseProduct, setSelectedBaseProduct] = useState<any>([]);
    const [selectedBaseProductId, setSelectedBaseProductId] = useState('');

    const handleOptionChange = (event: any, idx: any, options: any) => {
        const variantOptionId = event.target.value;
        for (let i = 0; i < options.length; i++) {
            if (options[i].option.id == variantOptionId.trim()) {
                options[i].selected = true;
            } else {
                options[i].selected = false;
            }
        }
        variantData[idx].options = options;
        setVariantData([...variantData]);
    };

    const constructSelectedVariants = () => {
        const variants: any[] = [];
        for (let i = 0; i < variantData.length; i++) {
            const variant: any = {};
            variant.variantId = variantData[i].variant.id;
            const optionId = constructVariantOptionPayload(variantData[i].options);
            if(!optionId) {
                throw new Error("please select "+variantData[i].variant.variantName+" option");
            }
            variant.optionId = optionId;
            variants.push(variant);
        }
        return variants;
    }

    const constructVariantOptionPayload = (variantOptions: any[]): string => {
        let optionId: any;
        for (let i = 0; i < variantOptions.length; i++) {
            if (variantOptions[i].selected) {
                optionId = variantOptions[i].option.id;
            }
        }
        return optionId;
    }

    const validateAndSaveLinkVariantFormData = () => {
        try {
            const productFormData = props.productFormData;
            if(productFormData.get('productType') == 'NORMAL') {
                return;
            }
            if (!selectedBaseProduct || !selectedBaseProductId) {
                throw new Error("Please Select Base Product");
            }
            if (productFormData.has('baseProductId')) {
                productFormData.delete('baseProductId');
            }
            productFormData.append('baseProductId', selectedBaseProductId);
            if (productFormData.has('variants')) {
                productFormData.delete('variants');
            }
            productFormData.append('variants', JSON.stringify(constructSelectedVariants()));
        } catch (error: any) {
            console.log(error);
            throw (error);
        }
    }

    const goBack = () => {
        try {
            props.onPreviousCallback();
        } catch (error: any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const doSubmit = (event: any) => {
        try {
            event.preventDefault();
            validateAndSaveLinkVariantFormData();
            props.onNextCallback();
        } catch (error: any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const updateEditLinkVariant = (data: any) => {
        try {
            product = data;
            if (data.baseProduct) {
                const baseProducts: any[] = [];
                baseProducts.push(data.baseProduct);
                onBaseProductSelected(baseProducts);
            }
        } catch (error: any) {
            throw (error);
        }
    }

    const handleSearch = async (query: string) => {
        setIsLoading(true);
        const paginationProduct: any = await baseProductApiService.getAllBaseProduct(query);
        setOptions(paginationProduct.items);
        setIsLoading(false);
    };

    const onBaseProductSelected = async (selectedItem: any) => {
        setSelectedBaseProduct(selectedItem);
        setSelectedBaseProductId(selectedItem[0].id);
        const baseProductDetails: any = await baseProductApiService.getBaseProductById(selectedItem[0].id);
        const variants: any[] = baseProductDetails.baseProductVariants;
        for (let i = 0; i < variants.length; i++) {
            for (let j = 0; j < product.variants.length; j++) {
                if (variants[i].variant.id == product.variants[j].variant.id) {
                    selectDefaultVariantOption(variants[i].options, product.variants[j].option);
                }
            }
        }
        setVariantData([...baseProductDetails.baseProductVariants]);

    }

    const selectDefaultVariantOption = (variantOptios: any[], productOption: any) => {
        for (let i = 0; i < variantOptios.length; i++) {
            if (variantOptios[i].option.id == productOption.id) {
                variantOptios[i].selected = true;
            } else {
                variantOptios[i].selected = false;
            }
        }
    }

    const getProductById = async () => {
        try {
            let data = await productApiService.getProductById(props.productId);
            updateEditLinkVariant(data);
            console.log("getProductById", data)
        } catch (error) {
            console.log("getProductById Error");
            console.log(error);
        }
    }

    useEffect(() => {
        getProductById();
    }, [props.data, props.productId]);

    return <>
        <Container>
            <Form className="needs-validation" onSubmit={doSubmit}>
                <Form.Group className="mt-3" controlId="productNameCtrlId">
                    <Row>
                        <Col md={2}>
                            <Form.Label>Base Product<span>*</span></Form.Label>
                        </Col>
                        <Col md={7}>
                            <AsyncTypeahead
                                disabled={props.write}
                                filterBy={() => true}
                                id="product-search-box"
                                isLoading={isLoading}
                                labelKey="name"
                                minLength={1}
                                onChange={onBaseProductSelected}
                                onSearch={handleSearch}
                                options={options}
                                placeholder="Search by products"
                                selected={selectedBaseProduct}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mt-5" controlId="productNameCtrlId">
                    {variantData.length > 0 && variantData.map((variantData: any, idx: any) => (
                        <Row>
                            <Col md={2}>
                                <Form.Label>Variant Name</Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Label>{variantData.variant.variantName}</Form.Label>
                            </Col>
                            <Col md={1}>
                                <Form.Label>Option<span>*</span></Form.Label>
                            </Col>
                            <Col md={3}>
                                <select className="form-control"
                                    disabled={props.write}
                                    onChange={(e) => { handleOptionChange(e, idx, variantData.options) }}>
                                    <option value="">Select option</option>
                                    {variantData.options && variantData.options.map((item: any, idx: any) => (
                                        <option key={idx} selected={item.selected} value={item.option.id}>{item.option.option}</option>
                                    ))}
                                </select>
                            </Col>
                        </Row>
                    ))}
                </Form.Group>
                <Form.Group className="mb-3" controlId="productMoqCtrlId">
                    <Row className="justify-content-end">
                        <Col className="text-end">
                            <Button type="button" variant="danger" className="my-4 mx-2"
                                onClick={goBack}
                            >Back</Button>
                       
                            <Button type="submit"  className="btn btn-primary my-4 mx-2">Next</Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Container>

    </>
}
export default EditLinkVariant;