import { Col, Modal, Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash,faEye, faUpload } from "@fortawesome/free-solid-svg-icons";
import ProductApiService from "../../data/services/product/product-api-service";
import { useContext, useEffect, useState } from "react";
import Paginator from "../../common/pagenator/pagenator";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import EditProduct from "./edit-product";
import ProductGallery from "./components/product-gallery";
import DeleteProductGallery from "./components/delete-product-gallery";
import { hasAccessPrivilege } from "../../account/helper/access-privilege";
import { UserContext, UserContextData } from "../../context/user-context";

const ProductDatatable = () => {
    const productApiService: ProductApiService = new ProductApiService();
    const [productData, setProductData] = useState<any>([]);
    const [totalPages, setTotalpages] = useState(0);
    const [activePage, setActivePage] = useState(0);
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const [showImageGallery, setShowImageGallery] = useState(false);
    const [showViewImgGallery, setShowViewImgGallery] = useState(false);

    // for permission
    const userContextData: UserContextData = useContext<UserContextData>({...UserContext});
    const [hasEditProductPrivilege, setHasEditProductPrivilege] = useState(false);
    const [hasDeleteProductPrivilege, setHasDeleteProductPrivilege] = useState(false);
    const [hasViewProductPrivilege, setHasViewProductPrivilege] = useState(false);


    const handleViewGalleryImgClose = () => {
        setShowViewImgGallery(false);
    }

    const handleClose = () =>{
        setShow(false);
        getAllProductData(activePage);
    }  
    
    const handleShow = () => setShow(true);
    const handleGalleryImgClose = () => setShowImageGallery(false);
    const handleGalleryImgShow = () => setShowImageGallery(true);
    const [productId, setProductId] = useState(null);
    const getAllProductData = async (pageNo?: number) => {
        try {
            let url = "?page=" + page + "&pageSize=" + 30;

            if (pageNo) {
                url = "?page=" + pageNo + "&pageSize=" + 30;
            }
            let result = await productApiService.getAllProduct(url);
            console.log("getDimensionData", result);
            paginationSetup(result.meta);
            setProductData(result.items);
        } catch (error:any) {
            console.log("getDimensionData Error");
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const deleteProduct = async (id: any) => {
        console.log('Product Id=' + id);
        try {
            await productApiService.deleteProduct(id);
            toast.success('Product Deleted Successfully', { containerId: 'TR' });
            await getAllProductData();
            return;

        } catch (error) {
            console.log("ERROR occured deleteProduct() error=== " + JSON.stringify(error));
        }
    }
    const paginationSetup = (meta: any) => {
        setTotalpages(meta.totalPages);
        setActivePage(meta.currentPage);
    }

    const confirmDelete = (id: any) => {
        confirmAlert({
            title: "Confirm",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteProduct(id),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const [on, setOn] = useState(false);
    const handleOn = () => setOn(true);
    const handleOff = () => setOn(false);

    const openDialog = (data: any) => {
        setOn(true);
        setProductData(data);
    }

    const updateProduct = (id: any) => {

        if (!hasViewProductPrivilege) {
            toast.error("you don't have permission to view it", { containerId: 'TR' });
            return;
        }
        setProductId(id);
        handleShow();
    }

    const addImageGallery = (id: any) => {
        setProductId(id);
        setShowImageGallery(true);
    }

    const viewImgGallery = (id: any) => {
        setProductId(id);
        setShowViewImgGallery(true);
    }

    const onPageClickCallback = (pageNo: number) => {
        getAllProductData(pageNo);
    }

    const checkPermission = () => {
        const editProductPrivilege = hasAccessPrivilege(userContextData, "PRODUCT", "EDIT_PRODUCT");
        setHasEditProductPrivilege(editProductPrivilege);
        const deleteProductPrivilege = hasAccessPrivilege(userContextData, "PRODUCT", "DELETE_PRODUCT");
        setHasDeleteProductPrivilege(deleteProductPrivilege);
        const viewProductPrivilege = hasAccessPrivilege(userContextData, "PRODUCT", "VIEW_PRODUCT");
        setHasViewProductPrivilege(viewProductPrivilege);

    }

    useEffect(() => {
        getAllProductData(1);
        checkPermission();
    }, []);

    return <>

        <Col className="grid-container">
            <Table className='all-package table-category' bordered size="white">
                <thead className="sticky-header">
                    <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Base Product</th>
                        <th>SKU</th>
                        {
                            (hasEditProductPrivilege || hasDeleteProductPrivilege) && <th>Actions</th>
                        }
                    </tr>                              
                </thead>
                <tbody>
                    {productData.map((data: any, idx: any) => (
                        <tr>
                            <td data-field="defaultImageUrl"><img src={data.svgImgUrl || data.imageURL} height={50} width={50} alt={data.productCode} title={data.productCode}/></td>
                            <td  style={{cursor: "pointer", textDecoration: "underline" }} title="Click to View" data-field="name">
                                <a  href="javascript:void(0)" onClick={() => updateProduct( data.productId)}>
                                {data.name}
                            </a></td>
                            <td data-field="Category Name">{data.category.length > 0 ? data.category[0].name : ''}</td>
                            <td data-field="moq">{data.baseProductName ? data.baseProductName: '' }</td>
                            <td data-field="sku">{data.sku}</td>
                            {
                                (hasEditProductPrivilege || hasDeleteProductPrivilege) && (
                                    <td>
                                    {hasEditProductPrivilege && 
                                        <a onClick={() => updateProduct(data.productId)}>
                                            <FontAwesomeIcon icon={faPenToSquare} className='me-2 font-success' />
                                        </a>
                                    }
                                    {
                                        hasDeleteProductPrivilege && <a href="javascript:void(0)" onClick={() => confirmDelete(data.productId)} className="cursor-pointer">
                                        <FontAwesomeIcon icon={faTrash} title="delete base product" className='me-2 mx-2 delete-btn' />
                                        </a>
                                    }
                                {/* < a href="javascript:void(0)" onClick={() => addImageGallery(data.productId)} className="cursor-pointer">
                                    <FontAwesomeIcon icon={faUpload} title="Add Gallery Images" className='me-2 mx-2 font-success' />
                                    </a>
                                    < a href="javascript:void(0)" onClick={() => viewImgGallery(data.productId)} className="cursor-pointer">
                                        <FontAwesomeIcon icon={faEye} title="View Gallery Images" className='me-2 mx-2 font-success' />
                                    </a> */}
                                </td>
                                )
                            }
                                </tr>
                            ))}
            </tbody>
            <Modal size='lg' show={on} onHide={handleOff}>
                                        <Modal.Header closeButton>
                                            <h4 className="center">Email Details</h4>
                                        </Modal.Header>
                                        <table className="table all-package table-category " id="editableTable">
                                            <Table striped bordered hover variant="white">
                                                <thead >
                                                    <tr><th>No</th>
                                                        <th> Email</th>
                                                        <th>cc</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productData && productData.map((data: any, idx: any) => (
                                                        <tr key={idx}>
                                                            <td data-field="serialNumber">{idx + 1}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </table>
                                    </Modal>
        </Table>
        
    </Col >
    <Paginator totalPages={totalPages} onPageClick={onPageClickCallback} activePage={activePage}></Paginator>
        <Modal show={show} dialogClassName="modal-90w"
            onHide={handleClose}
            backdrop="static"
            keyboard={false} size="xl">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <EditProduct updateProduct={updateProduct} productId={productId} handleClose={handleClose} />
            </Modal.Body>
        </Modal>

        <Modal show={showImageGallery} dialogClassName="modal-90w"
            onHide={handleGalleryImgClose}
            backdrop="static"
            keyboard={false} size="xl">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <ProductGallery productId={productId} handleClose={handleGalleryImgClose} />
            </Modal.Body>
        </Modal>

        <Modal show={showViewImgGallery} dialogClassName="modal-90w"
            onHide={handleViewGalleryImgClose}
            backdrop="static"
            keyboard={false} size="xl">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <DeleteProductGallery productId={productId} handleClose={handleViewGalleryImgClose} />
            </Modal.Body>
        </Modal>

    </>

}
export default ProductDatatable