import HttpClientWrapper from "../../api/http-client-wrapper";

class FeatureApiService {
    private httpClientWrapper: HttpClientWrapper;
    constructor(){
        this.httpClientWrapper = new HttpClientWrapper();
    }
    createFeature = async (payload:any) => {
        try {
            let data: any = await this.httpClientWrapper.post('v1/features', payload);
            console.log("FeatureApiService createFeature() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAllFeature = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('v1/features');
            console.log("FeatureApiService getAllFeature() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAllFeatureTreeView = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('v1/features/treeView');
            console.log("FeatureApiService getAllFeatureTreeView() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getFeatureById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/features/' + id);
            console.log("FeatureApiService getFeatureById() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getPermissionByFeatureId = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/features/' + id +'/permissions');
            console.log("FeatureApiService getPermissionByFeatureId() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getFeatureByRoleId = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/roles/' + id +'/features');
            console.log("FeatureApiService getFeatureByRoleId() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    editFeature = async (id: any,payload:any ) => {
        try {
            let data: any = await this.httpClientWrapper.put('/v1/features/' + id, payload);
            console.log("FeatureApiService editFeature() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


    deleteFeature = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/features/' + id);
            console.log("FeatureApiService deleteFeature() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }
}
export default FeatureApiService