import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormHeader from "../../../components/form-header/form-header";
import VariantApiService from "../../../data/services/variant/variant-api-service";
import addVariant from "./add-variant"
import { confirmAlert } from "react-confirm-alert";
import axios, { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { UserContextData, UserContext } from "../../../context/user-context";
import { hasAccessPrivilege } from "../../../account/helper/access-privilege";


const VariantDatatable = () => {
    const variantApiService: VariantApiService = new VariantApiService();
    const [variantData, setVariantData] = useState<any>([]);
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('VARIANTS'));
    const userContextData: UserContextData = useContext<UserContextData>({ ...UserContext });
    const [hasEditVariantPrivilege, setHasEditVariantPrivilege] = useState(false);
    const [hasDeleteVariantPrivilege, setHasDeleteVariantPrivilege] = useState(false);
    const [hasAddVariantPrivilege, setHasAddVariantPrivilege] = useState(false);

    const getVariantData = async () => {
        try {
            let result = await variantApiService.getAllVariant();
            console.log("getVariantData", result);
            setVariantData(result);

        } catch (error) {
            console.log("getVariantData Error");
            console.log(error);
        }
    }

    const addVariant = () => {
        navigate('/main-layout/add-variant')
    }

    const updateVariant = (id: any) => {
        navigate('/main-layout/edit-variant', { state: { id: id } });
    }

    const deleteVariant = async (id: any) => {
        console.log('Variant Id=' + id);
        try {
            await variantApiService.deleteVariant(id);
            toast.success('Variant Deleted Successfully', { containerId: 'TR' });
            await getVariantData();
            return;

        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }

        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const openAlert = (data: any) => {
        confirmAlert({
            title: "Alert Message",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteVariant(data),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const checkPermission = () => {
        const editVariantPrivilege = hasAccessPrivilege(userContextData, "VARIANTS", "EDIT_VARIANT");
        setHasEditVariantPrivilege(editVariantPrivilege);
        const deleteVariantPrivilege = hasAccessPrivilege(userContextData, "VARIANTS", "DELETE_VARIANT");
        setHasDeleteVariantPrivilege(deleteVariantPrivilege);
        const addVariantPrivilege = hasAccessPrivilege(userContextData, "VARIANTS", "ADD_VARIANT");
        setHasAddVariantPrivilege(addVariantPrivilege);

    }

    useEffect(() => {
        getVariantData();
        checkPermission();
    }, []);
    return <>
        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="mb-5 p-4">
                        <Col className="card-header">
                            {hasAddVariantPrivilege && <Button type="button" onClick={addVariant}
                                className="btn btn-primary add-row mt-md-0 mt-2">
                                <FontAwesomeIcon icon={faCirclePlus} />
                                &nbsp;Add New
                            </Button>
                            }
                        </Col>
                        <Card.Body className="card-body p-4">

                            <Col >
                                <Table className='all-package table-category' bordered size="white" striped hover>
                                    <thead className='sticky-header' >
                                        <tr>
                                            <th>Variant Name</th>
                                            <th>Options</th>
                                            {
                                                (hasEditVariantPrivilege || hasDeleteVariantPrivilege) && <th>Actions</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {variantData.map((data: any, idx: any) => (
                                            <tr>
                                                <td data-field="name">{data.variantName}</td>
                                                <td data-field="name">{data.variantOptionNames ? data.variantOptionNames : 'No options'} </td>
                                                {
                                                    (hasEditVariantPrivilege || hasDeleteVariantPrivilege) && (
                                                        <td>
                                                            {hasEditVariantPrivilege &&
                                                                <a href="javascript:void(0)" onClick={() => updateVariant(data.id)} className="cursor-pointer" >
                                                                    <FontAwesomeIcon title="Edit variant" icon={faPenToSquare} className='me-2 mx-2 font-success' />
                                                                </a>
                                                            }
                                                            {hasDeleteVariantPrivilege &&
                                                                <a href="javascript:void(0)" onClick={() => openAlert(data.id)} className="cursor-pointer">
                                                                    <FontAwesomeIcon icon={faTrash} title="Delete variant" className='me-2 mx-2 delete-btn' />
                                                                </a>
                                                            }

                                                        </td>
                                                    )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </Col>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>




    </>
}
export default VariantDatatable