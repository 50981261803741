import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormHeader from "../../../components/form-header/form-header";
import CategoriesApiService from "../../../data/services/category/category-api-service";
import axios, { AxiosError } from 'axios';
import { CategoriesPayload } from "../../../data/services/category/category-payload";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";

const AddCategory = () => {

    const categoriesApiService: CategoriesApiService = new CategoriesApiService;
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ADD CATEGORY'));
    const [name, setName] = useState('');
    const [level, setLevel] = useState('');
    const [categoryData, setCategoryData] = useState<any[]>([]);
    const [parentCategoryId, setParentCategoryId] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    let navigate: any = useNavigate();

    const handleSortOrderChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*$/.test(inputValue) || inputValue === '') {
            setSortOrder(inputValue);
        }
    };

    const saveCategories = async (event: any) => {
        try {
            event.preventDefault();
            if (!name) {
                toast.error("Category Name mustn't be empty");
            }
            let payload: CategoriesPayload = {
                name: name,
                sortOrder: sortOrder
            }
            if (parentCategoryId) {
                payload['parentId'] = parentCategoryId;
            }
            await categoriesApiService.createCategories(payload);
            toast.success('Categories Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/categories-datatable')
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Categories Create Failed", error);
            toast.error(error, { containerId: 'TR' });
        }

    }
    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const goBack = () => {
        navigate('/main-layout/categories-datatable');
    }

    const getCategoryData = async () => {
        try {
            let data = await categoriesApiService.getAllCategories();
            console.log('category data', data);
            setCategoryData(data);
        } catch (error) {
            console.log(error);
        }

    }
    const onCategoryChange = async (e: any) => {
        const parentId: string = e.target.value;
        setParentCategoryId(parentId);
        if (parentId == '') {
            return;
        }
    };

    useEffect(() => {
        getCategoryData();
    }, []);

    return <>
        {/* <FormHeader title="Add Categories" /> */}
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card >
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={saveCategories}>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={3}>
                                                <Form.Label>Category Name<span>*</span></Form.Label>

                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    className=""
                                                    type="text"
                                                    autoComplete="off"
                                                    onChange={e => { setName(e.target.value) }}
                                                    required
                                                />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={3}>
                                                <Form.Label>Sort Order<span>*</span></Form.Label>

                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    className=""
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Sort Order"
                                                    onChange={handleSortOrderChange}
                                                    value={sortOrder}
                                                    required
                                                />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={3}>

                                                <Form.Label>Parent Category</Form.Label>
                                            </Col>
                                            <Col md={8}>

                                                <Form.Control
                                                    as="select"
                                                    className="mb-3"
                                                    onChange={(e) => { onCategoryChange(e) }}
                                                >
                                                    <option value="">Select Parent Category (optional)</option>
                                                    {categoryData && categoryData.map((data: any, idx: any) => (
                                                        <option key={idx} value={data.id}>{data.name}</option>
                                                    ))}
                                                </Form.Control>

                                            </Col>


                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row >
                                            <Col xs={11} md={11} className="d-flex justify-content-end">
                                                <Button type="button" variant="danger" className="secondary mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" >Save</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>
                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>

    </>
}

export default AddCategory