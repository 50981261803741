import HttpClientWrapper from "../../api/http-client-wrapper";

class VariantTypeApiService {
  

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllVariantType = async() => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/variants' );
            console.log("VariantApiService getAllVariantType() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getVariantTypeById = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/variants/'+id );
            console.log("VariantApiService getVariantById() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    createVariantType = async(payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.post('/v1/variants',payload );
            console.log("VariantApiService createVariant() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    createVariantTypeDependent = async(id:any,payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.post('/v1/variants/'+id+ '/dependents',payload );
            console.log("VariantApiService createVariantTypeDependent() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    editVariantType = async(id:any, payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.put('/v1/variants/'+id, payload );
            console.log("VariantApiService editVariant() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    deleteVariantType = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.delete('/v1/variantTypes/'+id );
            console.log("VariantApiService deleteVariant() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }
}
export default VariantTypeApiService;